export enum wcagNameAndLevel {
  WCAG_v20_A = 'WCAG_v20_A',
  WCAG_v20_AA = 'WCAG_v20_AA',
  WCAG_v20_AAA = 'WCAG_v20_AAA',
  WCAG_v21_A = 'WCAG_v21_A',
  WCAG_v21_AA = 'WCAG_v21_AA',
  WCAG_v21_AAA = 'WCAG_v21_AAA',
  WCAG_v22_A = 'WCAG_v22_A',
  WCAG_v22_AA = 'WCAG_v22_AA',
  WCAG_v22_AAA = 'WCAG_v22_AAA',
}

export enum standardNameAndLevel {
  WCAG_v20_A = 'WCAG_v20_A',
  WCAG_v20_AA = 'WCAG_v20_AA',
  WCAG_v20_AAA = 'WCAG_v20_AAA',
  WCAG_v21_A = 'WCAG_v21_A',
  WCAG_v21_AA = 'WCAG_v21_AA',
  WCAG_v21_AAA = 'WCAG_v21_AAA',
  WCAG_v22_A = 'WCAG_v22_A',
  WCAG_v22_AA = 'WCAG_v22_AA',
  WCAG_v22_AAA = 'WCAG_v22_AAA',
  essential_v10_A = 'essential_v10_A',
}

export enum scanningType {
  singlePage = 'singlePage',
  entireSite = 'entireSite',
  customLinks = 'customLinks',
  sitemap = 'sitemap',
  manualAudit = 'manualAudit',
  ciTool = 'ciTool',
  automatedUserFlow = 'automatedUserFlow',
  mobile = 'mobile',
}

export const automatedScanningTypes: readonly scanningType[] = Object.freeze([
  scanningType.singlePage,
  scanningType.customLinks,
  scanningType.entireSite,
  scanningType.ciTool,
  scanningType.automatedUserFlow,
  scanningType.mobile,
]);

/**
 * Determines the scanningTypes that are not processed by the platform backend and
 * instead will directly store the raw results generated by an external testing tool.
 */
export const rawResultsScanningTypes: readonly scanningType[] = Object.freeze([
  // Regular web scans performed by the browser extension or legacy CI tools from the repository `eadevelopment/testingframeworks`.
  scanningType.ciTool,

  // Mobile testing scans performed by the Continuum SDK with additional Android/iOS details stored in the {@link IMobileScan.mobile} field.
  scanningType.mobile,
]);

/**
 * Determines the scanningTypes that cannot be rerun since they are executed by external tools or manual audits.
 */
export const cannotRerunScanningTypes: readonly scanningType[] = Object.freeze([
  scanningType.manualAudit,
  ...rawResultsScanningTypes,
]);

// Note: scanningJobType and scanningJobTypeName must have the same properties name

export enum scanningJobType {
  accessibility = 'accessibility',
  accessibility_access = 'accessibility_access',
  accessibility_axe = 'accessibility_axe',
  accessibility_ibm = 'accessibility_ibm',
  accessibility_wave = 'accessibility_wave',
  screenshot = 'screenshot',
}

export const scanningJobTypeName: { [key in keyof typeof scanningJobType]: string } = Object.freeze({
  [scanningJobType.accessibility]: 'Accessibility',
  [scanningJobType.accessibility_access]: 'Access Engine',
  [scanningJobType.accessibility_axe]: 'aXe Accessibility',
  [scanningJobType.accessibility_ibm]: 'IBM Accessibility',
  [scanningJobType.accessibility_wave]: 'Wave Accessibility',
  [scanningJobType.screenshot]: 'Screenshot',
});

export enum scanStatus {
  cancelled = 'cancelled',
  completed = 'completed',
  completed_with_errors = 'completed_with_errors',
  error = 'error',
  pending = 'pending',
  processing = 'processing',
  removed = 'removed',
  scheduled = 'scheduled',
}

export const completedStatuses: readonly scanStatus[] = Object.freeze([
  scanStatus.cancelled,
  scanStatus.completed,
  scanStatus.completed_with_errors,
  scanStatus.removed,
]);

export enum publicScanStatus {
  not_started = 'not_started',
  in_progress = 'in_progress',
  completed = 'completed',
  scheduled = 'scheduled',
  failed = 'failed',
  removed = 'removed',
  completed_with_errors = 'completed_with_errors',
}

export const PUBLIC_SCAN_GET_SCANS_LIMIT_DEFAULT: number = 5;
export const PUBLIC_SCAN_GET_SCANS_STATUS_DEFAULT: publicScanStatus[] = [
  publicScanStatus.completed,
  publicScanStatus.completed_with_errors,
];

export const COMPLETED_SCAN_STATUS: string[] = [scanStatus.completed, scanStatus.completed_with_errors, scanStatus.error];

export enum scanRecurrenceType {
  once = 'once',
  repeat_every = 'repeat_every',
}

export enum scanAuthenticationType {
  no_authentication = 'no_authentication',
  basic_authentication = 'basic_authentication',
  secure_web_authentication = 'secure_web_authentication',
}

export enum scanRecurrenceEvery {
  week = 'Week',
  biweekly = 'Biweekly',
  month = 'Month',
}

export enum scanRecurrenceWeekDay {
  monday = 'Monday',
  tuesday = 'Tuesday',
  wednesday = 'Wednesday',
  thursday = 'Thursday',
  friday = 'Friday',
  saturday = 'Saturday',
  sunday = 'Sunday',
}

export enum scanRecurrenceMonth {
  january = 'January',
  february = 'February',
  march = 'March',
  april = 'April',
  may = 'May',
  june = 'June',
  july = 'July',
  august = 'August',
  september = 'September',
  october = 'October',
  november = 'November',
  december = 'December',
}

export enum ScanJobStatus {
  pending = 'pending',
  processing = 'processing',
  completed = 'completed',
  cancelled = 'cancelled',
  error = 'error',
}

export const scanJobPendingStatuses: readonly ScanJobStatus[] = Object.freeze([ScanJobStatus.pending, ScanJobStatus.processing]);

export enum scanErrorReason {
  timeout = 'timeout',
}

export enum ScanOrigin {
  quick_scan = 'quick_scan',
  advanced = 'advanced',
  monitoring = 'monitoring',
  rerun = 'rerun',
  public_api = 'public_api',
  developer_tools = 'developer_tools',
  browser_extension = 'browser_extension',
  pages = 'pages',
  mobile_sdk = 'mobile_sdk',
  mobile_emulator = 'mobile_emulator',
}

export enum scanFindingArchivalState {
  processing = 'processing',
  done = 'done',
}

export enum manualAuditStatus {
  completed = 'completed',
  inProgress = 'inProgress',
  onHold = 'onHold',
  pendingReview = 'pendingReview',
  readyToStart = 'readyToStart',
}
