import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { IMobileScanOperatingSystem, IScan } from '../../../shared/interfaces/scan.interface';
import { UserPropertyService } from './user-property.service';
import { ICurrentSelectedProperty } from './user.service';
import { RestService } from './rest.service';
import { IMobileScanRuleInfo } from '../interfaces/mobile-scan.interface';

@Injectable({
  providedIn: 'root',
})
export class ScanMobileService {
  constructor(
    private restService: RestService,
    private userPropertyService: UserPropertyService,
  ) {}

  public getOperatingSystems(): Observable<IMobileScanOperatingSystem[]> {
    return this.userPropertyService
      .currentSelectedProperty()
      .pipe(
        mergeMap(
          ({ workspaceId, digitalPropertyId }: ICurrentSelectedProperty): Observable<IMobileScanOperatingSystem[]> =>
            this.restService.getMobileEmulatorScansOperatingSystems(workspaceId, digitalPropertyId),
        ),
      );
  }

  public getMobileScansRules(workspaceId: string, digitalPropertyId: string): Observable<IMobileScanRuleInfo[]> {
    return this.restService.getMobileScansRules(workspaceId, digitalPropertyId);
  }

  public removeMobileScan(workspaceId: string, digitalPropertyId: string, scanId: string): Observable<IScan> {
    return this.restService.removeMobileScan(workspaceId, digitalPropertyId, scanId);
  }
}
