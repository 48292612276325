import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpEvent, HttpEventType, HttpHeaders, HttpParams, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, last, map, tap, filter } from 'rxjs/operators';
import { omitBy, isNil } from 'lodash';

import { ITranslationClientConfig, ITranslationsClient } from '../../../shared/interfaces/translations.interface';
import { ISupportRequest } from '../../../shared/interfaces/support-request.interface';
import { ResourceUtility } from '../utility/resource.utility';
import { RestBuilder } from './helpers/rest.builder';
import { ITrackingConsoleRequest } from '../../../shared/interfaces/tracking.interface';
import { IAuditRule, IAuditRuleLibrary, IAuditRuleServerResponse } from '../../../shared/interfaces/audit-rule.interface';
import { IJobIndustriesServerResponse } from '../../../shared/interfaces/job-industry.interface';
import { IJobTitlesServerResponse } from '../../../shared/interfaces/job-title.interface';
import { IDigitalPropertyType } from '../../../shared/interfaces/digital-property.interface';
import {
  IActiveScan,
  IGetScansQuery,
  IGetScansServerResponse,
  IQuickScanOptions,
  IScan,
  ISendEmailsRequest,
  ISupplementalScanOption,
  IMobileScanOperatingSystem,
} from '../../../shared/interfaces/scan.interface';
import { IDashboardLatestScansServerResponse } from '../../../shared/interfaces/dashboard-scan-result.interface';
import { IDismissRestoreRequest, IToggleRuleRequest } from '../../../shared/interfaces/ignore.interface';
import { IScanOptions } from '../../../shared/interfaces/scan-options.interface';
import { Api, ApiHeaderOption, ApiQueryOption } from '../../../shared/constants/api';
import { IScanCreateRequest } from '../../../shared/interfaces/scan-create-request.interface';
import {
  IAnalyticsByRuleResult,
  IAnalyticsResultItem,
  IPageAffectedByRuleAnalytics,
} from '../../../shared/interfaces/analytics.interface';
import { RuleAuditHistoryType } from '../../../shared/constants/rule-audit-history';
import {
  IHasHistoryUpdateResponse,
  IRuleAuditHistoryGetQuery,
  IRuleAuditHistoryLineItem,
} from '../../../shared/interfaces/rule-audit-history.interface';
import { AccessibilityAuditToolNames, AuditToolNames } from '../../../shared/constants/audit-tool';
import { $scanSearch } from '../../../shared/constants/scan';
import {
  IDpSummaryRequestQuery,
  IFlawIdPageSummaryServerResponse,
  IPageSummary,
  IPageSummaryServerResponse,
  ISummaryRequestQuery,
  IUserFlowScanPointSummary,
  IUserFlowScanPointSummaryServerResponse,
} from '../../../shared/interfaces/scan-summaries.interface';
import { IDocumentDetails, IDocumentServerResponse } from '../../../shared/interfaces/document.interface';
import { IGetIssuesRequestQuery, IScanIssues } from '../../../shared/interfaces/scan-issues.interface';
import { IFlawPageReport, IFlawStatusUserHistory } from '../../../shared/interfaces/flaw.interface';
import { IGetShortLinkResponse, IShortLink } from '../../../shared/interfaces/short-link.interface';
import { IGetPagesRequestQuery } from '../../../shared/interfaces/monitoring-page.interface';
import { $sortingOrder } from '../../../shared/constants/sort';
import { SharedCommonUtility } from '../../../shared/utils/common.utility';
import { IAuditDbRule } from '../../../shared/interfaces/audit-rule.db.interface';
import { IAuditRuleCreateRequest, IAuditRuleEditRequest } from '../../../shared/interfaces/audit-rule.request.interface';
import { IAppConfig } from '../../../shared/interfaces/app-config.interface';
import { IUserCountryResponse, IUserResponse } from '../../../shared/interfaces/user.interface';
import { IUpdateImportantPagesRequest } from '../../../shared/interfaces/monitoring-page.interface';
import { IScreenshotAuditResult } from '../../../shared/interfaces/audit-raw-result.interface';
import {
  IDiscoveredScannableDocumentResponse,
  IScannableDocumentUploadResponse,
} from '../../../shared/interfaces/scannable-document.interface';
import { IMonitoringPage } from '../../../shared/interfaces/monitoring-page.interface';

import { EmptyObject } from '../../../shared/interfaces/empty-object.interface';
import { IAsyncReportResponse, IScanReportResponse } from '../../../shared/interfaces/async-report.interface';
import {
  ICustomAuditRuleWithStandards,
  ICreateCustomAuditRules,
  ICustomAuditDbRule,
  IExistsCustomAuditRulesQuery,
  IGetCustomAuditRulesQuery,
  IEditCustomAuditRules,
} from '../../../shared/interfaces/custom-audit-rule.interface';
import { SharedObjectUtility } from '../../../shared/utils/object.utility';
import { IUserRole } from '../../../shared/interfaces/user-role.interface';
import { IExistsResponse } from '../../../shared/interfaces/common.interface';
import { IAuditedDocument } from '../../../shared/interfaces/audited-document.interface';
import { IUploadClient } from '../../../shared/interfaces/uploads.interface';
import { IAuditStandard } from '../../../shared/interfaces/audit-standard.interface';
import { IMobileScanRuleInfo } from '../interfaces/mobile-scan.interface';

const CONSOLE_DEBOUNCE_TIMEOUT: number = 1000;

class BeaconDebouncer {
  private subscription: Subscription;
  private dataSubject$: Subject<ITrackingConsoleRequest>;

  constructor(timeout: number, url: string) {
    this.subscription = new Subscription();
    this.dataSubject$ = new Subject<ITrackingConsoleRequest>();

    const debouncedError$: Observable<ITrackingConsoleRequest> = this.dataSubject$.pipe(debounceTime(timeout));

    this.subscription.add(
      debouncedError$.subscribe({
        next: (consoleData: ITrackingConsoleRequest): void => {
          if (navigator.onLine) {
            try {
              window.navigator.sendBeacon(url, JSON.stringify(consoleData));
            } catch (error: any) {
              // catch all
            }
          }
        },
      }),
    );
  }

  public destroy(): void {
    this.subscription.unsubscribe();
  }

  public sendDebounced(data: ITrackingConsoleRequest): void {
    this.dataSubject$.next(data);
  }
}

export interface AuditRulesParams {
  limit?: number;
  skip?: number;
  filters?: { [key: string]: string[] };
  sortBy?: string;
  sortOrder?: $sortingOrder;
}

@Injectable()
export class RestService implements OnDestroy {
  private restBuilder: RestBuilder;
  private consoleDebouncer: BeaconDebouncer;

  constructor(private httpClient: HttpClient) {
    this.restBuilder = new RestBuilder();
    this.consoleDebouncer = new BeaconDebouncer(
      CONSOLE_DEBOUNCE_TIMEOUT,
      this.restBuilder.create().tracking().console().getApiUrl(),
    );
  }

  private static getStreamProgressPercents(event: HttpEvent<any>): number | null {
    return event.type === HttpEventType.UploadProgress ? Math.round((100 * event.loaded) / event.total) : null;
  }

  public ngOnDestroy(): void {
    this.consoleDebouncer.destroy();
  }

  public downloadRaw(
    url: string,
    params: Record<string, string | number | boolean | ReadonlyArray<string | number | boolean>> = {},
  ): Observable<HttpResponse<ArrayBuffer>> {
    const httpParams: HttpParams = new HttpParams({ fromObject: params });

    return this.httpClient.get(url, {
      observe: 'response',
      params: httpParams,
      responseType: 'arraybuffer',
    });
  }

  public download(
    url: string,
    params: Record<string, string | number | boolean | ReadonlyArray<string | number | boolean>> = {},
  ): Observable<void> {
    return this.downloadRaw(url, params).pipe(map(ResourceUtility.downloadResponse));
  }

  public getNewAccessToken(refreshToken?: string): Observable<HttpResponse<IUserResponse>> {
    const url: string = this.restBuilder.create().auth().refreshAccessToken().getApiUrl();
    const headers: Record<string, any> = SharedCommonUtility.notNullish(refreshToken)
      ? { ['x-refresh-token']: refreshToken, [ApiHeaderOption.skipLoader]: 'true' }
      : { [ApiHeaderOption.skipLoader]: 'true' };

    return this.httpClient.get<IUserResponse>(url, {
      headers: new HttpHeaders(headers),
      observe: 'response',
    });
  }

  // GET /config
  public getAppConfig(): Observable<IAppConfig> {
    const url: string = this.restBuilder.create().config().getApiUrl();

    return this.httpClient.get<IAppConfig>(url);
  }

  // GET /translations/:locale
  public getTranslations(locale: string): Observable<ITranslationsClient> {
    const url: string = this.restBuilder.create().translations(locale).getApiUrl();

    return this.httpClient.get<ITranslationsClient>(url);
  }

  // GET /translations/data/:locale
  public translationsData(locale: string): Observable<ITranslationsClient> {
    const url: string = this.restBuilder.create().translationsData(locale).getApiUrl();

    return this.httpClient.get<ITranslationsClient>(url);
  }

  // GET /translations/all?locale=en-us
  public downloadTranslations(...locales: string[]): Observable<void> {
    const locale: string[] = locales.filter((lang: string): boolean => typeof lang === 'string' && lang.trim().length > 0);
    const httpParams: HttpParams = new HttpParams({ fromObject: { locale } });

    const url: string = this.restBuilder.create().translations().all().query(httpParams).getApiUrl();

    return this.download(url, { locale });
  }

  // POST /translations
  public uploadTranslations(file: File): Observable<void> {
    const formData = new FormData();
    formData.append('file', file, file.name);
    const url: string = this.restBuilder.create().translations().getApiUrl();
    return this.httpClient.post<void>(url, formData);
  }

  // PUT /translations/:locale
  public saveAllTranslations(locale: string, data: ITranslationsClient): Observable<void> {
    const url: string = this.restBuilder.create().translations(locale).getApiUrl();

    return this.httpClient.put<void>(url, data);
  }

  // GET /translations/list
  public getListAvailableTranslations(): Observable<ITranslationClientConfig[]> {
    const url: string = this.restBuilder.create().translations().list().getApiUrl();

    return this.httpClient.get<ITranslationClientConfig[]>(url);
  }

  // GET /translations/list
  public getCountriesListForClient(): Observable<IUserCountryResponse> {
    const url: string = this.restBuilder.create().translations().countries().getApiUrl();

    return this.httpClient.get<IUserCountryResponse>(url);
  }

  // DELETE /translations
  public removeTranslation(key: string): Observable<void> {
    const url: string = this.restBuilder.create().translations().getApiUrl();

    return this.httpClient.delete<void>(url, { params: { keys: key } });
  }

  public getSupportedLocales(): Observable<ITranslationClientConfig[]> {
    const url: string = this.restBuilder.create().translations().supportedLocales().getApiUrl();

    return this.httpClient.get<ITranslationClientConfig[]>(url);
  }

  public createLocale(config: ITranslationClientConfig): Observable<void> {
    const url: string = this.restBuilder.create().translations().locales().getApiUrl();

    return this.httpClient.post<void>(url, config);
  }

  public updateLocale(locale: string, config: Partial<ITranslationClientConfig>): Observable<void> {
    const url: string = this.restBuilder.create().translations().locales(locale).getApiUrl();

    return this.httpClient.put<void>(url, config);
  }

  public removeLocale(locale: string): Observable<EmptyObject> {
    const url: string = this.restBuilder.create().translations().locales(locale).getApiUrl();

    return this.httpClient.delete<EmptyObject>(url);
  }

  // POST /scan/options
  public saveScanOptions(data: IScanOptions): Observable<IScanOptions> {
    const url: string = this.restBuilder.create().scan().options().getApiUrl();

    return this.httpClient.post<IScanOptions>(url, data);
  }

  // GET /scan/options/all
  public getScanOptionsList(): Observable<IScanOptions[]> {
    const url: string = this.restBuilder.create().scan().options().all().getApiUrl();

    return this.httpClient.get<IScanOptions[]>(url);
  }

  // PUT /scan/options
  public updateScanOptions(formData: IScanOptions): Observable<IScanOptions> {
    const url: string = this.restBuilder
      .create()
      .scan()
      .options(formData.selectedScanOptionsId as string)
      .getApiUrl();

    return this.httpClient.put<IScanOptions>(url, formData);
  }

  // PUT /scan/options/default
  public setDefaultScanOptions(scanOptionsId: string): Observable<IScanOptions> {
    const url: string = this.restBuilder.create().scan().options().default().getApiUrl();

    const data: { scanOptionsId: string } = {
      scanOptionsId: scanOptionsId,
    };

    return this.httpClient.put<IScanOptions>(url, data);
  }

  // Delete /scan/options/:id
  public removeScanOptions(id: string): Observable<IScanOptions> {
    const url: string = this.restBuilder.create().scan().options(id).getApiUrl();

    return this.httpClient.delete<IScanOptions>(url, {});
  }

  // GET /workspaces/:workspaceId/scan?
  public getScans(
    workspaceId: string,
    digitalPropertyId: string,
    params: IGetScansQuery,
    limit: number,
    skip: number,
    sortBy: string = null,
  ): Observable<IGetScansServerResponse> {
    if (typeof limit === 'number') {
      params.limit = String(limit);
    }

    if (typeof skip === 'number') {
      params.skip = String(skip);
    }

    if (typeof sortBy === 'string') {
      params[ApiQueryOption.sortBy] = sortBy;
    }

    const url: string = this.restBuilder.create().workspaces(workspaceId).id(digitalPropertyId).scan().getApiUrl();

    const httpParams: HttpParams = new HttpParams({
      fromObject: omitBy(params, isNil) as any,
    });

    return this.httpClient.get<IGetScansServerResponse>(url, { params: httpParams });
  }

  // GET /admin/scan/active
  public getActiveScans(skip: number, limit: number): Observable<IActiveScan[]> {
    const url: string = this.restBuilder.create().admin().scan().active().getApiUrl();

    const params: any = {
      skip: skip,
      limit: limit,
    };

    const httpParams: HttpParams = new HttpParams({
      fromObject: params,
    });

    return this.httpClient.get<IActiveScan[]>(url, {
      params: httpParams,
      headers: {
        [ApiHeaderOption.skipLoader]: 'true',
      },
    });
  }

  // GET /workspaces/:workspaceId/:digitalPropertyId/scan/active
  public getDigitalPropertyActiveScans(workspaceId: string, digitalPropertyId: string): Observable<IActiveScan> {
    const url: string = this.restBuilder.create().workspaces(workspaceId).id(digitalPropertyId).scan().active().getApiUrl();

    return this.httpClient.get<IActiveScan>(url, {
      headers: {
        [ApiHeaderOption.skipLoader]: 'true',
      },
    });
  }

  // GET /workspaces/:workspaceId/scan/:id
  public getScan(workspaceId: string, digitalPropertyId: string, scanId: string): Observable<IScan> {
    const url: string = this.restBuilder.create().workspaces(workspaceId).id(digitalPropertyId).scan(scanId).getApiUrl();

    return this.httpClient.get<IScan>(url, {
      headers: {
        [ApiHeaderOption.skipLoader]: 'true',
      },
    });
  }

  // GET /workspaces/:workspaceId/scan/mobile-scans/operating-systems
  public getMobileScansOperatingSystems(
    workspaceId: string,
    digitalPropertyId: string,
  ): Observable<IMobileScanOperatingSystem[]> {
    const scanBaseUrl: RestBuilder = this.restBuilder.create().workspaces(workspaceId).id(digitalPropertyId).scan();
    const url: string = scanBaseUrl.mobileScans().operatingSystems().getApiUrl();
    return this.httpClient.get<IMobileScanOperatingSystem[]>(url);
  }

  // GET /workspaces/:workspaceId/:digitalPropertyId/scan/:id/audited-documents/urls/:url
  public getAuditedDocument(
    workspaceId: string,
    digitalPropertyId: string,
    scanId: string,
    urlReference: string,
  ): Observable<IAuditedDocument> {
    const url: string = this.restBuilder
      .create()
      .workspaces(workspaceId)
      .id(digitalPropertyId)
      .scan(scanId)
      .auditedDocuments()
      .urls(urlReference)
      .getApiUrl();

    return this.httpClient.get<IAuditedDocument>(url);
  }

  // GET /workspaces/:workspaceId/scan/:id/documents
  public getScanDiscoveredDocuments(
    workspaceId: string,
    digitalPropertyId: string,
    scanId: string,
  ): Observable<IDiscoveredScannableDocumentResponse> {
    const url: string = this.restBuilder
      .create()
      .workspaces(workspaceId)
      .id(digitalPropertyId)
      .scan(scanId)
      .documents()
      .getApiUrl();

    return this.httpClient.get<IDiscoveredScannableDocumentResponse>(url, {
      headers: {
        [ApiHeaderOption.skipLoader]: 'true',
      },
    });
  }

  public getScanIssues(
    workspaceId: string,
    digitalPropertyId: string,
    scanId: string,
    tool: AccessibilityAuditToolNames,
    rule: string,
    scanUrl?: string,
    componentId?: string,
    scanPointId?: string,
  ): Observable<IScanIssues> {
    const url = this.restBuilder
      .create()
      .workspaces(workspaceId)
      .id(digitalPropertyId)
      .scan()
      .issues()
      .pathParam(scanId)
      .pathParam(tool)
      .pathParam(rule)
      .getApiUrl();

    const params: IGetIssuesRequestQuery = {};

    if (typeof scanUrl === 'string') {
      params[ApiQueryOption.url] = encodeURIComponent(scanUrl);
    }

    if (SharedCommonUtility.notNullish(componentId)) {
      params[ApiQueryOption.componentId] = encodeURIComponent(componentId);
    }

    if (SharedCommonUtility.notNullish(scanPointId)) {
      params[ApiQueryOption.scanPointId] = encodeURIComponent(scanPointId);
    }

    const httpParams: HttpParams = new HttpParams({
      fromObject: params as unknown as { [param: string]: string | readonly string[] },
    });

    return this.httpClient.get<IScanIssues>(url, { params: httpParams });
  }

  public getScanIssueShortLink(
    workspaceId: string,
    digitalPropertyId: string,
    scanId: string,
    tool: AccessibilityAuditToolNames,
    rule: string,
    issueId: string,
  ): Observable<IGetShortLinkResponse> {
    const url = this.restBuilder
      .create()
      .workspaces(workspaceId)
      .id(digitalPropertyId)
      .scan()
      .issues()
      .pathParam(scanId)
      .pathParam(tool)
      .pathParam(rule)
      .shortLink()
      .id(issueId)
      .getApiUrl();

    return this.httpClient.get<IGetShortLinkResponse>(url);
  }

  // GET /workspaces/:workspaceId/scan/:scanId/screenshots/:documentId
  public getScreenshotAuditDetails(
    workspaceId: string,
    digitalPropertyId: string,
    scanId: string,
    documentId: string,
  ): Observable<IScreenshotAuditResult> {
    const url = this.restBuilder
      .create()
      .workspaces(workspaceId)
      .id(digitalPropertyId)
      .scan(scanId)
      .screenshots()
      .id(documentId)
      .getApiUrl();

    return this.httpClient.get<IScreenshotAuditResult>(url);
  }

  // GET /workspaces/:workspaceId/scan/:scanId/audited-documents/:documentId/screenshot
  public getAuditedDocumentScreenshot(
    workspaceId: string,
    digitalPropertyId: string,
    scanId: string,
    documentId: string,
  ): Observable<IUploadClient> {
    const url = this.restBuilder
      .create()
      .workspaces(workspaceId)
      .id(digitalPropertyId)
      .scan(scanId)
      .auditedDocuments()
      .id(documentId)
      .screenshot()
      .getApiUrl();

    return this.httpClient.get<IUploadClient>(url);
  }

  // GET /workspaces/:workspaceId/:digitalPropertyId/monitoring-analytics/tool/:toolName?scanId=<scanId>&scanHistoryLimit=<scanHistoryLimit>
  public getScanAnalytics(
    workspaceId: string,
    digitalPropertyId: string,
    scanId: string,
    toolName: AccessibilityAuditToolNames,
    scanHistoryLimit: number,
  ): Observable<IAnalyticsResultItem[]> {
    const url: string = this.restBuilder.create().workspaces(workspaceId).id(digitalPropertyId).monitoringAnalytics().getApiUrl();

    const params: any = {
      scanHistoryLimit,
    };

    const httpParams: HttpParams = new HttpParams({
      fromObject: params,
    });

    return this.httpClient.get<IAnalyticsResultItem[]>(url, { params: httpParams });
  }

  // GET /workspaces/:workspaceId/:digitalPropertyId/monitoring-analytics/report?format=<fileExtension>
  public getMonitoringHistoryReport(workspaceId: string, digitalPropertyId: string): Observable<IAsyncReportResponse> {
    const url: string = this.restBuilder
      .create()
      .workspaces(workspaceId)
      .id(digitalPropertyId)
      .monitoringAnalytics()
      .report()
      .getApiUrl();

    return this.httpClient.get<IAsyncReportResponse>(url);
  }

  // POST /workspaces/:workspaceId/:digitalPropertyId/monitoring-analytics/report
  public generateMonitoringHistoryReport(workspaceId: string, digitalPropertyId: string): Observable<IAsyncReportResponse> {
    const url: string = this.restBuilder
      .create()
      .workspaces(workspaceId)
      .id(digitalPropertyId)
      .monitoringAnalytics()
      .report()
      .getApiUrl();

    return this.httpClient.post<IAsyncReportResponse>(url, {});
  }

  // GET /workspaces/:workspaceId/:digitalPropertyId/monitoring-analytics/rules
  public getScanAnalyticsByRule(workspaceId: string, digitalPropertyId: string): Observable<IAnalyticsByRuleResult> {
    const url: string = this.restBuilder
      .create()
      .workspaces(workspaceId)
      .id(digitalPropertyId)
      .monitoringAnalytics()
      .rules()
      .getApiUrl();

    return this.httpClient.get<IAnalyticsByRuleResult>(url);
  }

  // GET /workspaces/:workspaceId/:digitalPropertyId/monitoring-analytics/rule/:ruleId
  public getPageAnalyticsAffectedByRule(
    workspaceId: string,
    digitalPropertyId: string,
    ruleName: string,
  ): Observable<IPageAffectedByRuleAnalytics[]> {
    const url: string = this.restBuilder
      .create()
      .workspaces(workspaceId)
      .id(digitalPropertyId)
      .monitoringAnalytics()
      .rule(ruleName)
      .getApiUrl();

    return this.httpClient.get<IPageAffectedByRuleAnalytics[]>(url);
  }

  // POST /workspaces/:workspaceId/scan
  public createScan(workspaceId: string, digitalPropertyId: string, formData: FormData): Observable<IScan> {
    const url: string = this.restBuilder.create().workspaces(workspaceId).id(digitalPropertyId).scan().getApiUrl();

    return this.httpClient.post<IScan>(url, formData);
  }

  // POST /workspaces/:workspaceId/scan/quick-scan
  public createAutomatedScan(workspaceId: string, digitalPropertyId: string, options: IQuickScanOptions): Observable<IScan> {
    const url: string = this.restBuilder.create().workspaces(workspaceId).id(digitalPropertyId).scan().quickScan().getApiUrl();

    return this.httpClient.post<IScan>(url, options);
  }

  // PUT /scan/:id
  public updateScan(workspaceId: string, digitalPropertyId: string, formData: IScanCreateRequest, id: string): Observable<IScan> {
    const url: string = this.restBuilder.create().workspaces(workspaceId).id(digitalPropertyId).scan(id).getApiUrl();

    return this.httpClient.put<IScan>(url, formData);
  }

  // POST /workspaces/:workspaceId/:digitalPropertyId/scan/:id/supplementalScan
  public getOrCreateSupplementalScan(
    workspaceId: string,
    digitalPropertyId: string,
    id: string,
    link: string,
  ): Observable<string> {
    const url: string = this.restBuilder
      .create()
      .workspaces(workspaceId)
      .id(digitalPropertyId)
      .scan(id)
      .supplementalScan()
      .getApiUrl();

    const body: ISupplementalScanOption = {
      url: link,
    };

    return this.httpClient.post<string>(url, body);
  }

  // DELETE /workspaces/:workspaceId/scan/:id
  // Note: about DELETE and "body" https://stackoverflow.com/questions/299628/is-an-entity-body-allowed-for-an-http-delete-request
  public removeScan(workspaceId: string, digitalPropertyId: string, id: string): Observable<IScan> {
    const url: string = this.restBuilder.create().workspaces(workspaceId).id(digitalPropertyId).scan(id).getApiUrl();

    return this.httpClient.delete<IScan>(url);
  }

  // POST /workspaces/:workspaceId/scan/:id/rerun
  public rerunScan(workspaceId: string, digitalPropertyId: string, id: string): Observable<IScan> {
    const url: string = this.restBuilder.create().workspaces(workspaceId).id(digitalPropertyId).scan(id).rerun().getApiUrl();

    return this.httpClient.post<IScan>(url, {});
  }

  // GET /workspaces/:workspaceId/:digitalPropertyId/scan/:scanId/rule/:ruleId/report/download?scanId=<scanId>&format=<reportFormat>&siteUrl=<siteUrl>&auditTool=<AccessibilityAuditToolNames>
  public downloadScanReportByRule(
    workspaceId: string,
    digitalPropertyId: string,
    scanId: string,
    reportFormat: string,
    scanUrl: string = '',
    ruleId: string,
    extraQueryOptions: Record<string, any> = {},
  ): Observable<HttpResponse<ArrayBuffer>> {
    const url: string = this.restBuilder
      .create()
      .workspaces(workspaceId)
      .id(digitalPropertyId)
      .scan(scanId)
      .rule(ruleId)
      .report()
      .download()
      .getApiUrl();

    const params: any = {
      format: reportFormat,
      scanUrl: scanUrl,
      ...extraQueryOptions,
    };

    return this.downloadRaw(url, params);
  }

  // GET /workspaces/:workspaceId/:digitalPropertyId/scan/:scanId/report/download?format=<reportFormat>&siteUrl=<siteUrl>&auditTool=<AccessibilityAuditToolNames>
  public downloadFullScanReport(
    workspaceId: string,
    digitalPropertyId: string,
    scanId: string,
    reportFormat: string,
    scanUrl: string = '',
    auditTool: AccessibilityAuditToolNames,
  ): Observable<IScanReportResponse> {
    const url: string = this.restBuilder
      .create()
      .workspaces(workspaceId)
      .id(digitalPropertyId)
      .scan(scanId)
      .report()
      .download()
      .getApiUrl();

    const params: any = {
      format: reportFormat,
      scanUrl: scanUrl,
      auditTool: auditTool,
    };

    return this.httpClient.get<IScanReportResponse>(url, { params });
  }

  // POST /workspaces/:workspaceId/:digitalPropertyId/scan/:scanId/report/download?format=<reportFormat>&siteUrl=<siteUrl>&auditTool=<AccessibilityAuditToolNames>
  public generateFullScanReport(
    workspaceId: string,
    digitalPropertyId: string,
    scanId: string,
    reportFormat: string,
    scanUrl: string = '',
    auditTool: AccessibilityAuditToolNames,
  ): Observable<IScanReportResponse> {
    const url: string = this.restBuilder
      .create()
      .workspaces(workspaceId)
      .id(digitalPropertyId)
      .scan(scanId)
      .report()
      .download()
      .getApiUrl();

    const params: any = {
      format: reportFormat,
      scanUrl: scanUrl,
      auditTool: auditTool,
    };
    return this.httpClient.post<IScanReportResponse>(url, {}, { params });
  }

  // POST /workspaces/:workspaceId/scan/:id/reports/send-email
  public sendReportToEmails(
    workspaceId: string,
    digitalPropertyId: string,
    scanId: string,
    auditTool: AccessibilityAuditToolNames | null,
    emails: string[],
  ): Observable<string[]> {
    const url: string = this.restBuilder
      .create()
      .workspaces(workspaceId)
      .id(digitalPropertyId)
      .scan(scanId)
      .reports()
      .sendEmail()
      .getApiUrl();

    const body: ISendEmailsRequest = {
      auditTool: auditTool,
      emails: emails,
    };

    return this.httpClient.post<string[]>(url, body);
  }

  // POST /documents
  public createDocument(workspaceId: string, formData: FormData, onProgress: (progress: number) => void): Observable<void> {
    const url: string = this.restBuilder.create().workspaces(workspaceId).documents().getApiUrl();

    const req = new HttpRequest('POST', url, formData, {
      reportProgress: true,
    });

    const processProgressEvent = (progress: number | null): void => {
      if (typeof progress === 'number') {
        onProgress(progress);
      }
    };

    return this.httpClient.request(req).pipe(
      map((event: HttpEvent<any>): number | null => RestService.getStreamProgressPercents(event)),
      tap(processProgressEvent),
      last(),
      map((): void => {}),
    );
  }

  // GET /workspaces/:workspaceId/documents/:digitalPropertyId
  public getDocuments(
    workspaceId: string,
    digitalPropertyId: string,
    limit: number,
    skip: number,
    sortBy: string = null,
    sortOrder: $sortingOrder = null,
  ): Observable<IDocumentServerResponse> {
    const url: string = this.restBuilder.create().workspaces(workspaceId).documents().list().id(digitalPropertyId).getApiUrl();

    const params: { [param: string]: string | readonly string[] } = {};

    if (typeof limit === 'number') {
      params.limit = String(limit);
    }

    if (typeof skip === 'number') {
      params.skip = String(skip);
    }

    if (!SharedCommonUtility.isNullish(sortBy)) {
      params[ApiQueryOption.sortBy] = sortBy;
    }

    if (!SharedCommonUtility.isNullish(sortOrder)) {
      params[ApiQueryOption.sortOrder] = sortOrder;
    }

    const httpParams: HttpParams = new HttpParams({
      fromObject: params,
    });

    return this.httpClient.get<IDocumentServerResponse>(url, { params: httpParams });
  }

  // GET /workspaces/:workspaceId/documents/:documentId
  public getDocument(workspaceId: string, documentId: string): Observable<IDocumentDetails> {
    const url: string = this.restBuilder.create().workspaces(workspaceId).documents(documentId).getApiUrl();

    return this.httpClient.get<IDocumentDetails>(url);
  }

  // PUT /workspaceId/:workspaceId/documents/:documentId
  public updateDocument(
    workspaceId: string,
    formData: FormData,
    id: string,
    onProgress: (progress: number) => void,
  ): Observable<number> {
    const url: string = this.restBuilder.create().workspaces(workspaceId).documents(id).getApiUrl();

    const req = new HttpRequest('PUT', url, formData, {
      reportProgress: true,
    });

    const processProgressEvent = (progress: number | null): void => {
      if (typeof progress === 'number') {
        onProgress(progress);
      }
    };

    return this.httpClient.request(req).pipe(
      map((event: HttpEvent<any>): number | null => RestService.getStreamProgressPercents(event)),
      tap(processProgressEvent),
      last(),
    );
  }

  // DELETE /workspaces/:workspaceId/documents/:documentId
  public removeDocument(workspaceId: string, documentId: string): Observable<void> {
    const url: string = this.restBuilder.create().workspaces(workspaceId).documents(documentId).getApiUrl();

    return this.httpClient.delete<void>(url);
  }

  // DELETE /workspaces/:workspaceId/documents/:documentId/file/:fileId
  public removeDocumentAttachment(workspaceId: string, documentId: string, fileId: string): Observable<string[]> {
    const url: string = this.restBuilder.create().workspaces(workspaceId).documents(documentId).file(fileId).getApiUrl();

    return this.httpClient.delete<string[]>(url);
  }

  // POST /workspaces/:workspaceId/scannableDocuments/upload
  public createScannableDocument(
    workspaceId: string,
    formData: FormData,
    onProgress: (progress: number) => void,
  ): Observable<IScannableDocumentUploadResponse> {
    const url: string = this.restBuilder.create().workspaces(workspaceId).scannableDocuments().upload().getApiUrl();

    const req = new HttpRequest('POST', url, formData, {
      reportProgress: true,
    });

    const processProgressEvent = (progress: number | null): void => {
      if (typeof progress === 'number') {
        onProgress(progress);
      }
    };

    return this.httpClient.request(req).pipe(
      tap((event: HttpEvent<any>): void => {
        const progress: number | null = RestService.getStreamProgressPercents(event);
        if (SharedCommonUtility.notNullish(progress)) {
          processProgressEvent(progress);
        }
      }),
      filter((event: HttpEvent<any>): boolean => event instanceof HttpResponse),
      map((res: HttpResponse<IScannableDocumentUploadResponse>) => res.body),
    );
  }

  // POST /send-email
  public sendSupportRequest(supportRequest: ISupportRequest): Observable<ISupportRequest> {
    const url: string = this.restBuilder.create().support().sendSupportEmail().getApiUrl();

    return this.httpClient.post<ISupportRequest>(url, supportRequest);
  }

  // POST /audit-rules/workspaces/:workspaceId/digital-properties/:digitalPropertyId/toggle-rule
  public toggleRule(workspaceId: string, digitalPropertyId: string, request: IToggleRuleRequest): Observable<IAuditRule> {
    const url: string = this.restBuilder
      .create()
      .auditRules()
      .workspaces(workspaceId)
      .digitalProperties(digitalPropertyId)
      .toggleRule()
      .getApiUrl();

    return this.httpClient.post<IAuditRule>(url, request);
  }

  // POST /workspaces/:workspaceId/:digitalPropertyId:/flaws/dismiss
  public dismissIssues(
    workspaceId: string,
    digitalPropertyId: string,
    ignoreRequest: IDismissRestoreRequest,
  ): Observable<number> {
    const url: string = this.restBuilder.create().workspaces(workspaceId).id(digitalPropertyId).flaws().dismiss().getApiUrl();

    return this.httpClient
      .post<{ updatedCount: number }>(url, ignoreRequest)
      .pipe(map(({ updatedCount }: { updatedCount: number }) => updatedCount));
  }

  // POST /workspaces/:workspaceId/:digitalPropertyId/flaws/restore
  public restoreIssues(
    workspaceId: string,
    digitalPropertyId: string,
    restoreRequest: IDismissRestoreRequest,
  ): Observable<number> {
    const url: string = this.restBuilder.create().workspaces(workspaceId).id(digitalPropertyId).flaws().restore().getApiUrl();

    return this.httpClient
      .post<{ updatedCount: number }>(url, restoreRequest)
      .pipe(map(({ updatedCount }: { updatedCount: number }) => updatedCount));
  }

  // GET /digital-property-type
  public getDigitalPropertyTypes(): Observable<IDigitalPropertyType[]> {
    const url: string = this.restBuilder.create().digitalPropertyType().getApiUrl();

    return this.httpClient.get<IDigitalPropertyType[]>(url);
  }

  // POST /tracking/console
  public sendTrackingConsole(consoleData: ITrackingConsoleRequest): boolean {
    if (typeof window.navigator.sendBeacon === 'function') {
      this.consoleDebouncer.sendDebounced(consoleData);
      return true;
    }

    return false;
  }

  // GET /audit-rules/workspaces/:workspaceId/digital-properties/:digitalPropertyId
  public findDigitalPropertyAuditRules(
    workspaceId: string,
    digitalPropertyId: string,
    limit: number,
    skip: number,
    filters: { [key: string]: string[] } = {},
    excludeHistory: boolean = false,
    sortBy: string = null,
    sortOrder: $sortingOrder = null,
  ): Observable<IAuditRuleServerResponse> {
    const url: string = this.restBuilder
      .create()
      .auditRules()
      .workspaces(workspaceId)
      .digitalProperties(digitalPropertyId)
      .getApiUrl();

    const params: { [param: string]: string | readonly string[] } = {};

    if (typeof limit === 'number') {
      params.limit = String(limit);
    }

    if (typeof skip === 'number') {
      params.skip = String(skip);
    }

    if (excludeHistory) {
      params.exclude_history = String(1);
    }

    if (!SharedCommonUtility.isNullish(sortBy)) {
      params[ApiQueryOption.sortBy] = sortBy;
    }

    if ([$sortingOrder.asc, $sortingOrder.desc].includes(sortOrder)) {
      params[ApiQueryOption.sortOrder] = sortOrder;
    }

    Object.keys(filters).forEach((key: string): void => {
      params[key] = filters[key].join(',');
    });

    const httpParams: HttpParams = new HttpParams({
      fromObject: params,
    });

    return this.httpClient.get<IAuditRuleServerResponse>(url, { params: httpParams });
  }

  // GET /audit-rules
  public findAuditRules({
    limit,
    skip,
    filters,
    sortBy,
    sortOrder,
  }: AuditRulesParams = {}): Observable<IAuditRuleServerResponse> {
    const url: string = this.restBuilder.create().auditRules().getApiUrl();

    const params: { [param: string]: string | readonly string[] } = {};

    if (typeof limit === 'number') {
      params.limit = String(limit);
    }

    if (typeof skip === 'number') {
      params.skip = String(skip);
    }

    if (!SharedCommonUtility.isNullish(sortBy)) {
      params[ApiQueryOption.sortBy] = sortBy;
    }

    if ([$sortingOrder.asc, $sortingOrder.desc].includes(sortOrder)) {
      params[ApiQueryOption.sortOrder] = sortOrder;
    }

    if (SharedCommonUtility.notNullish(filters)) {
      Object.keys(filters).forEach((key: string): void => {
        params[key] = filters[key].join(',');
      });
    }

    const httpParams: HttpParams = new HttpParams({
      fromObject: params,
    });

    return this.httpClient.get<IAuditRuleServerResponse>(url, { params: httpParams });
  }

  // GET /audit-rules/libraries
  public getAuditRuleLibraries(): Observable<IAuditRuleLibrary[]> {
    const url: string = this.restBuilder.create().auditRules().libraries().getApiUrl();
    return this.httpClient.get<IAuditRuleLibrary[]>(url);
  }

  // GET /custom-audit-rules/standards
  public getStandardsForLibrary(libraryCode: string): Observable<IAuditStandard[]> {
    const url: string = this.restBuilder.create().customAuditRules().standards().getApiUrl();
    return this.httpClient.get<IAuditStandard[]>(url, {
      params: new HttpParams({
        fromObject: { [Api.library]: libraryCode },
      }),
    });
  }

  // GET /audit-rules/:ruleId/
  public findRuleByID(ruleId: string): Observable<IAuditRule> {
    const url: string = this.restBuilder.create().auditRules(ruleId).getApiUrl();
    return this.httpClient.get<IAuditRule>(url);
  }

  // POST /audit-rules/:ruleId/
  public editRuleById(editRequest: IAuditRuleEditRequest, ruleId: string): Observable<boolean> {
    const url: string = this.restBuilder.create().auditRules(ruleId).getApiUrl();
    return this.httpClient.put<boolean>(url, editRequest);
  }

  // POST /audit-rules
  public createAuditRule(creationRequest: IAuditRuleCreateRequest): Observable<IAuditDbRule> {
    const url: string = this.restBuilder.create().auditRules().getApiUrl();
    return this.httpClient.post<IAuditDbRule>(url, creationRequest);
  }

  // GET /audit-rules/rule-ids
  public ruleIds(): Observable<string[]> {
    const url: string = this.restBuilder.create().auditRules().ruleIds().getApiUrl();
    return this.httpClient.get<string[]>(url);
  }

  // DELETE /audit-rules/:ruleId/
  public deleteRule(ruleId: string): Observable<IAuditRule> {
    const url: string = this.restBuilder.create().auditRules(ruleId).getApiUrl();
    return this.httpClient.delete<IAuditRule>(url);
  }

  // GET /custom-audit-rules
  public findCustomAuditRules({
    filters,
    limit,
    skip,
    sortBy,
    sortOrder,
  }: IGetCustomAuditRulesQuery): Observable<IAuditRuleServerResponse> {
    const url: string = this.restBuilder.create().customAuditRules().getApiUrl();

    const params: Record<string, string | readonly string[]> = {};

    if (SharedObjectUtility.isNumber(limit)) {
      params.limit = limit;
    }

    if (SharedObjectUtility.isNumber(skip)) {
      params.skip = skip;
    }

    if (!SharedCommonUtility.isNullish(sortBy)) {
      params[ApiQueryOption.sortBy] = sortBy;
    }

    if ([$sortingOrder.asc, $sortingOrder.desc].includes(sortOrder)) {
      params[ApiQueryOption.sortOrder] = sortOrder;
    }

    if (SharedCommonUtility.notNullish(filters)) {
      Object.keys(filters).forEach((key: string): void => {
        params[key] = filters[key].join(',');
      });
    }

    const httpParams: HttpParams = new HttpParams({
      fromObject: params,
    });

    return this.httpClient.get<IAuditRuleServerResponse>(url, { params: httpParams });
  }

  // GET /custom-audit-rules/:ruleId
  public findCustomAuditRuleByRuleId(ruleId: string): Observable<ICustomAuditRuleWithStandards> {
    const url: string = this.restBuilder.create().customAuditRules(ruleId).getApiUrl();
    return this.httpClient.get<ICustomAuditRuleWithStandards>(url);
  }

  // PUT /custom-audit-rules/:ruleId
  public editCustomAuditRuleByRuleId(editRequest: IEditCustomAuditRules, ruleId: string): Observable<void> {
    const url: string = this.restBuilder.create().customAuditRules(ruleId).getApiUrl();
    return this.httpClient.put<void>(url, editRequest);
  }

  // DELETE /custom-audit-rules/:ruleId/
  public deleteCustomAuditRule(ruleId: string): Observable<void> {
    const url: string = this.restBuilder.create().customAuditRules(ruleId).getApiUrl();
    return this.httpClient.delete<void>(url);
  }

  // GET /custom-audit-rules/categories
  public getCustomAuditRuleCategories(): Observable<string[]> {
    const url: string = this.restBuilder.create().customAuditRules().categories().getApiUrl();
    return this.httpClient.get<string[]>(url);
  }

  // GET /custom-audit-rules/library
  public getCustomAuditRulesLibrary(): Observable<IAuditRuleLibrary[]> {
    const url: string = this.restBuilder.create().customAuditRules().library().getApiUrl();
    return this.httpClient.get<IAuditRuleLibrary[]>(url);
  }

  // GET /custom-audit-rules/exists
  public existsCustomAuditRule(query: IExistsCustomAuditRulesQuery): Observable<IExistsResponse> {
    const url: string = this.restBuilder.create().customAuditRules().exists().getApiUrl();
    const params: Record<string, string> = {};

    Object.entries(query).forEach(([key, value]: [string, any]): void => {
      params[key] = encodeURIComponent(value);
    });

    const httpParams: HttpParams = new HttpParams({
      fromObject: params,
    });

    return this.httpClient.get<IExistsResponse>(url, { params: httpParams });
  }

  // POST /custom-audit-rules
  public createCustomAuditRule(createData: ICreateCustomAuditRules): Observable<ICustomAuditDbRule> {
    const url: string = this.restBuilder.create().customAuditRules().getApiUrl();

    return this.httpClient.post<ICustomAuditDbRule>(url, createData);
  }

  // GET /job-industries
  public getJobIndustries(limit: number, skip: number): Observable<IJobIndustriesServerResponse> {
    const url: string = this.restBuilder.create().jobIndustries().getApiUrl();

    const params: { [param: string]: string | readonly string[] } = {};

    if (typeof limit === 'number') {
      params.limit = String(limit);
    }

    if (typeof skip === 'number') {
      params.skip = String(skip);
    }

    const httpParams: HttpParams = new HttpParams({
      fromObject: params,
    });

    return this.httpClient.get<IJobIndustriesServerResponse>(url, { params: httpParams });
  }

  // GET /job-titles
  public getJobTitles(limit: number, skip: number): Observable<IJobTitlesServerResponse> {
    const url: string = this.restBuilder.create().jobTitles().getApiUrl();

    const params: { [param: string]: string | readonly string[] } = {};

    if (typeof limit === 'number') {
      params.limit = String(limit);
    }

    if (typeof skip === 'number') {
      params.skip = String(skip);
    }

    const httpParams: HttpParams = new HttpParams({
      fromObject: params,
    });

    return this.httpClient.get<IJobTitlesServerResponse>(url, { params: httpParams });
  }

  public getScansCount(workspaceId: string, digitalPropertyId: string, excludeMonitoring: boolean): Observable<number> {
    const url: string = this.restBuilder
      .create()
      .workspaces(workspaceId)
      .id(digitalPropertyId)
      .dashboard()
      .scansCount()
      .getApiUrl();

    const params: any = {};

    if (excludeMonitoring) {
      params.exclude_monitoring = String(1);
    }

    const httpParams: HttpParams = new HttpParams({
      fromObject: params,
    });

    return this.httpClient
      .get<{ total: number }>(url, { params: httpParams })
      .pipe(map((total: { total: number }): number => total.total));
  }

  public getNextScheduledScan(workspaceId: string, digitalPropertyId: string): Observable<IScan> {
    const url: string = this.restBuilder
      .create()
      .workspaces(workspaceId)
      .id(digitalPropertyId)
      .scan()
      .nextScheduledScan()
      .getApiUrl();

    return this.httpClient.get<IScan>(url);
  }

  public moveScans(
    workspaceId: string,
    digitalPropertyId: string,
    newDigitalPropertyId: string,
    scans: string[],
  ): Observable<void> {
    const apiUrl: string = this.restBuilder.create().workspaces(workspaceId).id(digitalPropertyId).scan().move().getApiUrl();
    return this.httpClient.post<void>(apiUrl, {
      digitalPropertyId: newDigitalPropertyId,
      scans: scans,
    });
  }

  // POST /workspaces/:workspaceId/:digitalPropertyId/scan/scan-tags
  public updateScanTags(workspaceId: string, digitalPropertyId: string, scanTagId: string, scanIds: string[]): Observable<void> {
    const apiUrl: string = this.restBuilder.create().workspaces(workspaceId).id(digitalPropertyId).scan().scanTags().getApiUrl();
    return this.httpClient.post<void>(apiUrl, {
      scanTagId: scanTagId,
      scanIds: scanIds,
    });
  }

  // GET /shortLinks/:shortLink
  public getShortLink(id: string): Observable<IShortLink> {
    const url: string = this.restBuilder.create().shortLinks(id).getApiUrl();

    return this.httpClient.get<IShortLink>(url);
  }

  // GET /workspaces/:workspaceId/:digitalPropertyId/rule-history/:toolName/:rule
  public getRuleAuditHistory(
    workspaceId: string,
    digitalPropertyId: string,
    toolName: AuditToolNames,
    type: RuleAuditHistoryType,
    rule: string,
    data: IRuleAuditHistoryGetQuery,
  ): Observable<IRuleAuditHistoryLineItem[]> {
    const url: string = this.restBuilder
      .create()
      .workspaces(workspaceId)
      .id(digitalPropertyId)
      .ruleHistory()
      .pathParam(toolName)
      .pathParam(type)
      .pathParam(rule)
      .getApiUrl();

    return this.httpClient.post<IRuleAuditHistoryLineItem[]>(url, data);
  }

  public hasHistory(
    workspaceId: string,
    digitalPropertyId: string,
    toolName: AccessibilityAuditToolNames,
    type: RuleAuditHistoryType,
    dataId: string,
    from: Date,
    ruleId?: string,
    scanUrl?: string,
  ): Observable<IHasHistoryUpdateResponse> {
    let params: HttpParams = new HttpParams({ fromObject: { from: encodeURIComponent(from.toISOString()) } });

    if (typeof scanUrl === 'string') {
      params = params.set('url', encodeURIComponent(scanUrl));
    }
    if (typeof ruleId === 'string') {
      params = params.set('rule', encodeURIComponent(ruleId));
    }

    const url: string = this.restBuilder
      .create()
      .workspaces(workspaceId)
      .id(digitalPropertyId)
      .ruleHistory()
      .pathParam(toolName)
      .pathParam(type)
      .pathParam(dataId)
      .hasHistory()
      .query(params)
      .getApiUrl();

    return this.httpClient.get<IHasHistoryUpdateResponse>(url, {
      headers: {
        [ApiHeaderOption.skipLoader]: 'true',
      },
    });
  }

  public getDashboardLatestScans(
    workspaceId: string,
    digitalPropertyId: string,
    tool: AccessibilityAuditToolNames,
    query: IGetScansQuery,
    skip: number,
    limit: number,
  ): Observable<IDashboardLatestScansServerResponse> {
    query[$scanSearch.tool] = tool;
    query[$scanSearch.skip] = String(skip);
    query[$scanSearch.limit] = String(limit);

    const url: string = this.restBuilder
      .create()
      .workspaces(workspaceId)
      .id(digitalPropertyId)
      .dashboard()
      .latestScans()
      .getApiUrl();

    const httpParams: HttpParams = new HttpParams({ fromObject: query } as any);

    return this.httpClient.get<IDashboardLatestScansServerResponse>(url, {
      params: httpParams,
      headers: {
        [ApiHeaderOption.skipLoader]: 'true',
      },
    });
  }

  // GET /workspaces/:workspaceId/digital-property/:digitalPropertyId/scan/:scanId/page-summary/:documentId?toolName=:toolName
  public getPageSummaryForTool(
    workspaceId: string,
    digitalPropertyId: string,
    scanId: string,
    documentId: string,
    toolName: AccessibilityAuditToolNames,
  ): Observable<IPageSummary> {
    const url: string = this.restBuilder
      .create()
      .workspaces(workspaceId)
      .digitalProperty(digitalPropertyId)
      .scan(scanId)
      .pageSummary()
      .id(documentId)
      .getApiUrl();

    const httpParams: HttpParams = new HttpParams({ fromObject: { [ApiQueryOption.toolName]: toolName } });

    return this.httpClient.get<IPageSummary>(url, { params: httpParams });
  }

  // GET /workspaces/:workspaceId/digital-property/:digitalPropertyId/scan/:scanId/documents/:documentId/scan-point-summary/:userFlowScanPointId?toolName=:toolName
  public getUserFlowScanPointSummaryForTool(
    workspaceId: string,
    digitalPropertyId: string,
    scanId: string,
    documentId: string,
    scanPointId: string,
    toolName: AccessibilityAuditToolNames,
  ): Observable<IUserFlowScanPointSummary> {
    const url: string = this.restBuilder
      .create()
      .workspaces(workspaceId)
      .digitalProperty(digitalPropertyId)
      .scan(scanId)
      .documents(documentId)
      .scanPointSummary()
      .id(scanPointId)
      .getApiUrl();

    const httpParams: HttpParams = new HttpParams({ fromObject: { [ApiQueryOption.toolName]: toolName } });

    return this.httpClient.get<IUserFlowScanPointSummary>(url, { params: httpParams });
  }

  // GET /workspaces/:workspaceId/digital-property/:digitalPropertyId/scan/:scanId/page-summary?toolName=:toolName
  public getPageSummariesForTool(
    workspaceId: string,
    digitalPropertyId: string,
    scanId: string,
    params: ISummaryRequestQuery,
  ): Observable<IPageSummaryServerResponse> {
    const httpParams: HttpParams = new HttpParams({ fromObject: params as unknown as Record<string, string> });

    const url: string = this.restBuilder
      .create()
      .workspaces(workspaceId)
      .digitalProperty(digitalPropertyId)
      .scan(scanId)
      .pageSummary()
      .getApiUrl();

    return this.httpClient.get<IPageSummaryServerResponse>(url, { params: httpParams });
  }

  // GET /workspaces/:workspaceId/digital-property/:digitalPropertyId/scan/:scanId/page-summary/previous?toolName=:toolName
  public getPreviousPageSummariesForTool(
    workspaceId: string,
    digitalPropertyId: string,
    scanId: string,
    params: ISummaryRequestQuery,
  ): Observable<IPageSummaryServerResponse> {
    const httpParams: HttpParams = new HttpParams({ fromObject: params as unknown as Record<string, string> });

    const url: string = this.restBuilder
      .create()
      .workspaces(workspaceId)
      .digitalProperty(digitalPropertyId)
      .scan(scanId)
      .pageSummary()
      .previous()
      .getApiUrl();

    return this.httpClient.get<IPageSummaryServerResponse>(url, { params: httpParams });
  }

  // GET /workspaces/:workspaceId/digital-property/:digitalPropertyId/scan/:scanId/scan-point-summary
  public getPageSummariesForUserFlowScanPoints(
    workspaceId: string,
    digitalPropertyId: string,
    scanId: string,
    params: ISummaryRequestQuery,
  ): Observable<IUserFlowScanPointSummaryServerResponse> {
    const httpParams: HttpParams = new HttpParams({ fromObject: params as unknown as Record<string, string> });

    const url: string = this.restBuilder
      .create()
      .workspaces(workspaceId)
      .digitalProperty(digitalPropertyId)
      .scan(scanId)
      .scanPointSummary()
      .getApiUrl();

    return this.httpClient.get<IUserFlowScanPointSummaryServerResponse>(url, { params: httpParams });
  }

  // PUT /workspaces/:workspaceId/:digitalPropertyId:/flaws/:flawId/markAsViewed
  public markFlawAsViewed(workspaceId: string, digitalPropertyId: string, flawId: string): Observable<void> {
    const url: string = this.restBuilder
      .create()
      .workspaces(workspaceId)
      .id(digitalPropertyId)
      .flaws()
      .pathParam(flawId)
      .markAsViewed()
      .getApiUrl();

    return this.httpClient.put<void>(url, {});
  }

  // GET /workspaces/:workspaceId/:digitalPropertyId:/flaws/:flawId/userChangeHistory
  public getFlawHistory(workspaceId: string, digitalPropertyId: string, flawId: string): Observable<IFlawStatusUserHistory[]> {
    const url: string = this.restBuilder
      .create()
      .workspaces(workspaceId)
      .id(digitalPropertyId)
      .flaws()
      .pathParam(flawId)
      .userChangeHistory()
      .getApiUrl();

    return this.httpClient.get<IFlawStatusUserHistory[]>(url, {});
  }

  // GET /workspaces/:workspaceId/:digitalPropertyId/monitoring-pages
  public getDigitalPropertyMonitoringPages(
    workspaceId: string,
    digitalPropertyId: string,
    filters: IGetPagesRequestQuery = {},
  ): Observable<IMonitoringPage[]> {
    const url: string = this.restBuilder.create().workspaces(workspaceId).id(digitalPropertyId).monitoringPages().getApiUrl();

    const httpParams: HttpParams = new HttpParams({
      fromObject: filters as unknown as { [param: string]: string | readonly string[] },
    });

    return this.httpClient.get<IMonitoringPage[]>(url, { params: httpParams });
  }

  // GET /workspaces/:workspaceId/:digitalPropertyId/:componentId/monitoring-pages/page_summary?componentId=<componentId>
  public getDigitalPropertyMonitoringPagesForComponent(
    workspaceId: string,
    digitalPropertyId: string,
    params: IDpSummaryRequestQuery,
  ): Observable<IFlawIdPageSummaryServerResponse> {
    const url = this.restBuilder
      .create()
      .workspaces(workspaceId)
      .id(digitalPropertyId)
      .monitoringPages()
      .pageSummary()
      .getApiUrl();

    const httpParams: HttpParams = new HttpParams({
      fromObject: params as unknown as Record<string, string>,
    });

    return this.httpClient.get<IFlawIdPageSummaryServerResponse>(url, { params: httpParams });
  }

  // GET /workspaces/:workspaceId/:digitalPropertyId:/monitoring-pages/available
  public getAvailableDigitalPropertyMonitoringPages(
    workspaceId: string,
    digitalPropertyId: string,
  ): Observable<IMonitoringPage[]> {
    const url: string = this.restBuilder
      .create()
      .workspaces(workspaceId)
      .id(digitalPropertyId)
      .monitoringPages()
      .available()
      .getApiUrl();

    const params: IGetPagesRequestQuery = {};

    const httpParams: HttpParams = new HttpParams({
      fromObject: params as unknown as { [param: string]: string | readonly string[] },
    });

    return this.httpClient.get<IMonitoringPage[]>(url, { params: httpParams });
  }

  // GET /workspaces/:workspaceId/:digitalPropertyId/monitoring-pages/:flawPageId
  public getMonitoringFlawPage(
    workspaceId: string,
    digitalPropertyId: string,
    flawPageId: string,
    trendDataLength: number,
  ): Observable<IFlawPageReport> {
    const url: string = this.restBuilder
      .create()
      .workspaces(workspaceId)
      .id(digitalPropertyId)
      .monitoringPages(flawPageId)
      .getApiUrl();

    const params: IGetPagesRequestQuery = {};

    if (trendDataLength > 0) {
      params[ApiQueryOption.trendDataLength] = encodeURIComponent(trendDataLength);
    }

    const httpParams: HttpParams = new HttpParams({
      fromObject: params as unknown as { [param: string]: string | readonly string[] },
    });

    return this.httpClient.get<any>(url, { params: httpParams });
  }

  // PUT /workspaces/:workspaceId/:digitalPropertyId:/monitoring-pages/important
  public setImportantDigitalPropertyMonitoringPages(
    workspaceId: string,
    digitalPropertyId: string,
    flawPageIds: string[],
    important: boolean,
  ): Observable<void> {
    const url: string = this.restBuilder
      .create()
      .workspaces(workspaceId)
      .id(digitalPropertyId)
      .monitoringPages()
      .important()
      .getApiUrl();

    const body: IUpdateImportantPagesRequest = {
      pagesIds: flawPageIds,
      markAsImportant: important,
    };

    return this.httpClient.put<void>(url, body);
  }

  // GET /secure-resources/:resourceId
  public getResourceMoreSecureUrl(resourceId: string): string {
    return this.restBuilder.create().secureResources(resourceId).getApiUrl();
  }

  // GET /user-roles
  public getUserRoles(): Observable<IUserRole[]> {
    const url: string = this.restBuilder.create().userRoles().getApiUrl();

    return this.httpClient.get<IUserRole[]>(url);
  }

  // GET /workspaces/:workspaceId/scan/mobile-scans/rules
  public getMobileScansRules(workspaceId: string, digitalPropertyId: string): Observable<IMobileScanRuleInfo[]> {
    const scanBaseUrl: RestBuilder = this.restBuilder.create().workspaces(workspaceId).id(digitalPropertyId).scan();
    const url: string = scanBaseUrl.mobileScans().rules().getApiUrl();
    return this.httpClient.get<IMobileScanRuleInfo[]>(url);
  }

  // DELETE /workspaces/:workspaceId/digital-properties/:digitalPropertyId/mobile-apps/scans/:scanId
  public removeMobileScan(workspaceId: string, digitalPropertyId: string, scanId: string): Observable<IScan> {
    const url: string = this.restBuilder
      .create()
      .workspaces(workspaceId)
      .digitalProperties(digitalPropertyId)
      .mobileApps()
      .scans(scanId)
      .getApiUrl();

    return this.httpClient.delete<IScan>(url);
  }

  // GET /workspaces/:workspaceId/digital-properties/:digitalPropertyId/mobile-apps/scans/operating-systems
  public getMobileEmulatorScansOperatingSystems(
    workspaceId: string,
    digitalPropertyId: string,
  ): Observable<IMobileScanOperatingSystem[]> {
    const url: string = this.restBuilder
      .create()
      .workspaces(workspaceId)
      .digitalProperties(digitalPropertyId)
      .mobileApps()
      .scans()
      .operatingSystems()
      .getApiUrl();
    return this.httpClient.get<IMobileScanOperatingSystem[]>(url);
  }
}
