import { BrowserModule, Title } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';

import { SharedModule } from './shared/shared.module';
import { AppInitialization } from './app.init';
import { AppRoutingModule } from './app.routing';

import { AppComponent } from './app.component';
import { SvgImagesComponent } from './shared/svg-images.component';
import { SvgIconsComponent } from './shared/svg-icons.component';
import { TokenInterceptor } from './services/interceptors/token.interceptor';
import { BusyIndicatorInterceptor } from './services/busy-indicator/busy-indicator.interceptor';
import { ExtraHeadersInterceptor } from './services/interceptors/extra-headers.interceptor';

import { AuthGuard } from './services/auth/guards/auth.guard';
import { PendingChangesGuard } from './services/guards/pending-changes.guard';

import { WindowRef } from './services/window.ref';
import { FeaturesService } from './services/features.service';
import { BusMessageService } from './services/bus-message.service';
import { AuthService } from './services/auth.service';
import { A11yService } from './services/a11y.service';
import { RestService } from './services/rest.service';
import { BrowserLocalStorageService } from './services/browser-local-storage.service';
import { BrowserSessionStorageService } from './services/browser-session-storage.service';
import { TranslateService } from './translate/translate.service';
import { UserService } from './services/user.service';
import { BusyIndicatorService } from './services/busy-indicator/busy-indicator.service';
import { NotificationService } from './services/notification.service';
import { CookieService } from './services/cookie.service';
import { FormService } from './services/form.service';
import { ErrorMessageService } from './services/error-message.service';
import { ErrorHandlerService } from './services/error-handler.service';
import { StatusService } from './services/status.service';
import { ScanService } from './services/scan.service';
import { ScanOptionsService } from './services/scan-options.service';
import { ScanReportService } from './services/scan-report.service';
import { ScanMobileService } from './services/scan-mobile.service';
import { ReportService } from './services/report.service';
import { MessageBusService } from './services/message-bus.service';
import { SupportService } from './services/support.service';
import { BrowserService } from './services/browser.service';
import { AdminRestAPI } from './services/rest/admin.api';
import { UserRestAPI } from './services/rest/user.api';
import { WorkspacesRestAPI } from './services/rest/workspaces.api';
import { TrackingConsoleService } from './services/tracking/tracking-console.service';
import { ClipboardService } from './services/clipboard.service';
import { ManualAuditService } from './services/manual-audit.service';

import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { ToastComponent } from './components/toast/toast.component';
import { OverlayImageComponent } from './components/overlay-image/overlay-image.component';

import { HelpCenterComponent } from './pages/public/help-center/help-center.component';
import { NotFoundComponent } from './pages/public/not-found/not-found.component';
import { LoginComponent } from './pages/public/login/login.component';
import { LogoutComponent } from './pages/public/logout/logout.component';
import { PasswordResetComponent } from './pages/public/password-reset/password-reset.component';
import { ResendActivationLinkComponent } from './pages/public/activation-link/activation-link.component';
import { CookiePolicyComponent } from './pages/public/cookie-policy/cookie-policy.component';
import { VerifyEmailComponent } from './pages/public/verify-email/verify-email.component';
import { ColorContrastRuleSuggestionsComponent } from './components/color-contrast-rule-suggestions/color-contrast-rule-suggestions.component';
import { ForbiddenErrorComponent } from './pages/public/error/forbidden-error/forbidden-error.component';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { BreadcrumbsService } from './components/breadcrumbs/breadcrumbs.service';
import { BreadcrumbsConfig } from './resolvers/breadcrumbs/breadcrumbs.config';
import { AuditReportWrapperComponent } from './components/audit-report-wrapper/audit-report-wrapper.component';
import { LazyLoadingStylesService } from './services/lazy-loading-styles.service';
import { SvgImageFiltersComponent } from './shared/svg-image-filters.component';
import { AttributionComponent } from './pages/public/attribution/attribution.component';
import { CommentService } from './services/comment.service';
import { DashboardService } from './pages/public/dashboard/dashboard.service';
import { ManualAuditIssueService } from './services/manual-audit-issue.service';
import { LoaderService } from './services/loader.service';
import { RequestLoaderInterceptor } from './services/interceptors/request-loader.interceptor';
import { RuleStatusChangeComponent } from './components/rule-status-change/rule-status-change.component';
import { ServerVersionChangeModalComponent } from './components/server-version-change/server-version-change-modal.component';
import { ServerVersionInterceptor } from './services/interceptors/server-version.interceptor';
import { ShortLinkComponent } from './pages/public/short-link/short-link.component';
import { AppConfigService } from './services/app-config.service';
import { PageReportWrapperComponent } from './components/page-report-wrapper/page-report-wrapper.component';
import { TenantlessGuard } from './services/auth/guards/tenantless.guard.service';
import { EapAuthTokenInterceptor } from './services/interceptors/eap-auth-token.interceptor';
import { TenantRedirectModalComponent } from './pages/public/login/tenant-redirect-modal/tenant-redirect-modal.component';
import { NonSsoTenantGuard } from './services/auth/guards/non-sso-tenant.guard';
import {
  RedirectSsoTenantToAuth0LogoutGuard,
  RedirectSsoTenantToDashboardGuard,
  RedirectSsoTenantToSignupGuard,
} from './services/auth/guards/redirect-sso-tenant.guard';
import { AuthorizeOnlyStaffUsersSsoTenantGuard } from './services/auth/guards/authorize-only-staff-users-sso-tenant-guard';
import { NoAccessToTenantErrorComponent } from './pages/public/error/no-access-to-tenant-error/no-access-to-tenant-error.component';
import { NoWorkspaceInterceptor } from './services/interceptors/no-workspace.interceptor';
import { DesignReviewRuleSelectorModalComponent } from './components/design-review/design-review-rule-selector-modal/design-review-rule-selector-modal.component';
import { DeleteFindingConfirmationModalComponent } from './components/design-review/delete-finding-confirmation-modal/delete-finding-confirmation-modal.component';
import { MfaLoginComponent } from './pages/public/login/mfa-login/mfa-login.component';
import { HomePageRedirectGuard } from './services/auth/guards/home-page-redirect.guard';
import { TenantPackageInterceptor } from './services/interceptors/tenant-package.interceptor';
import { AppLayoutComponent } from './layouts/app-layout/app-layout.component';
import { FeatureFlagService } from './services/feature-flag/feature-flag.service';
import { FeatureFlagGuard } from './services/feature-flag/feature-flag.guard';
import { LoginLayoutComponent } from './layouts/login-layout/login-layout.component';
import { DigitalPropertyLimitGuard } from './services/auth/guards/digital-property-limit.guard';
import { CompoundNavbarModule } from './components/compound-navbar/compound-navbar.module';
import { AccountSettingsModule } from './pages/public/settings/account/settings/account-settings.module';
import { UserAclService } from './services/user-acl.service';
import { SuccessCriteriaService } from './services/success-criteria.service';
import { AiChatComponent } from './components/ai-chat/ai-chat.component';
import { SignupComponent } from './pages/public/signup/signup.component';
import { SignupPlatformUsageComponent } from './pages/public/signup/plaform-usage/signup-platform-usage.component';
import { SignupFormComponent } from './pages/public/signup/signup-form/signup-form.component';
import { SignupLayoutComponent } from './layouts/signup-layout/signup-layout.component';
import { SignupOverviewComponent } from './pages/public/signup/overview/signup-overview.component';
import { TenantLoginRedirectComponent } from './pages/public/login/tenant-login-redirect/tenant-login-redirect.component';
import { LoginCardComponent } from './pages/public/login/login-card/login-card.component';
import { SsoTenantGuard } from './services/auth/guards/sso-tenant.guard';
import { LinkedPropertyDataService } from './services/linked-property-data.service';
import { FeatureFlagInitializedComponent } from './components/feature-flag-initialized/feature-flag-initialized.component';

@NgModule({
  declarations: [
    AppComponent,
    SvgImagesComponent,
    SvgImageFiltersComponent,
    HeaderComponent,
    FooterComponent,
    NotFoundComponent,
    SignupComponent,
    LoginCardComponent,
    LoginComponent,
    TenantLoginRedirectComponent,
    LogoutComponent,
    TenantRedirectModalComponent,
    ShortLinkComponent,
    PasswordResetComponent,
    ResendActivationLinkComponent,
    CookiePolicyComponent,
    ToastComponent,
    FeatureFlagInitializedComponent,
    VerifyEmailComponent,
    HelpCenterComponent,
    OverlayImageComponent,
    ColorContrastRuleSuggestionsComponent,
    ForbiddenErrorComponent,
    BreadcrumbsComponent,
    AuditReportWrapperComponent,
    AttributionComponent,
    RuleStatusChangeComponent,
    ServerVersionChangeModalComponent,
    PageReportWrapperComponent,
    NoAccessToTenantErrorComponent,
    DesignReviewRuleSelectorModalComponent,
    DeleteFindingConfirmationModalComponent,
    MfaLoginComponent,
    AppLayoutComponent,
    LoginLayoutComponent,
    AiChatComponent,
    SignupFormComponent,
    SignupPlatformUsageComponent,
    SignupLayoutComponent,
    SignupOverviewComponent,
  ],
  exports: [SvgImagesComponent, SvgIconsComponent, SvgImageFiltersComponent],
  imports: [
    SharedModule,
    AppRoutingModule,
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    CompoundNavbarModule,
    AccountSettingsModule,
    // dummy config to make injector happy
    // external auth config will be updated in AppConfigService
    AuthModule.forRoot({
      domain: 'dummy',
      clientId: 'dummy',
    }),
  ],
  providers: [
    WindowRef,
    Title,
    AuthService,
    AuthGuard,
    HomePageRedirectGuard,
    A11yService,
    RestService,
    HttpClientModule,
    BrowserLocalStorageService,
    BrowserSessionStorageService,
    UserService,
    LinkedPropertyDataService,
    UserAclService,
    BusyIndicatorService,
    NotificationService,
    CookieService,
    FormService,
    ErrorMessageService,
    ManualAuditService,
    CommentService,
    DashboardService,
    StatusService,
    ScanService,
    ScanOptionsService,
    ScanReportService,
    ScanMobileService,
    ReportService,
    MessageBusService,
    SupportService,
    BrowserService,
    ClipboardService,
    AdminRestAPI,
    UserRestAPI,
    WorkspacesRestAPI,
    PendingChangesGuard,
    BreadcrumbsComponent,
    BreadcrumbsService,
    BreadcrumbsConfig,
    ManualAuditIssueService,
    LoaderService,
    TenantlessGuard,
    NonSsoTenantGuard,
    SsoTenantGuard,
    RedirectSsoTenantToDashboardGuard,
    RedirectSsoTenantToAuth0LogoutGuard,
    RedirectSsoTenantToSignupGuard,
    AuthorizeOnlyStaffUsersSsoTenantGuard,
    AuthHttpInterceptor,
    TokenInterceptor,
    AppConfigService,
    FeatureFlagService,
    FeatureFlagGuard,
    DigitalPropertyLimitGuard,
    SuccessCriteriaService,
    {
      provide: ErrorHandler,
      useClass: ErrorHandlerService,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: EapAuthTokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BusyIndicatorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ExtraHeadersInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestLoaderInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NoWorkspaceInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ServerVersionInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TenantPackageInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: AppInitialization,
      deps: [
        UserService,
        TranslateService,
        BusMessageService,
        FeaturesService,
        AppConfigService,
        TrackingConsoleService,
        LazyLoadingStylesService,
        LinkedPropertyDataService,
      ],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
