export enum FunctionalAreaSetting {
  monitoringPagesLimit = 'monitoringPagesLimit',
  pdfPagesLimit = 'pdfPagesLimit',
  academySeatsLimit = 'academySeatsLimit',
  digitalPropertyLimit = 'digitalPropertyLimit',
  monitoringPagesLimitWithFF = 'monitoringPagesLimitWithFF',
  userWayWebsiteLimit = 'userWayWebsiteLimit',
  digitalPropertyAutomatedFlowLimit = 'digitalPropertyAutomatedFlowLimit',
}

export enum $package {
  _id = '_id',
  name = 'name',
  settings = 'settings',
  roleIds = 'roleIds',
  roles = 'roles',
  createdAt = 'createdAt',
  updatedAt = 'updatedAt',
}

export enum $packageInstance {
  _id = '_id',
  tenantId = 'tenantId',
  packageId = 'packageId',
  packageDetails = 'packageDetails',
  originalPackage = 'originalPackage',
  packageAddonIds = 'packageAddonIds',
  packageAddons = 'packageAddons',
  createdAt = 'createdAt',
  updatedAt = 'updatedAt',
}

export enum $packageAddon {
  _id = '_id',
  name = 'name',
  description = 'description',
  roleIds = 'roleIds',
  availableIn = 'availableIn',
  featureFlag = 'featureFlag',
  createdAt = 'createdAt',
  updatedAt = 'updatedAt',
}

export enum PackageNames {
  Audit = 'Audit',
  LegacyEA = 'Legacy EA',
  Essentials_v2024 = 'Essentials 2024',
  EssentialsPlus_v2024 = 'Essentials+ 2024',
  Accelerate_v2024 = 'Accelerate 2024',
  AcceleratePlus_v2024 = 'Accelerate+ 2024',
  Enterprise_v2024 = 'Enterprise 2024',
  EnterprisePlus_v2024 = 'Enterprise+ 2024',
  Basic_v2025 = 'Basic 2025',
  Essentials_v2025 = 'Essentials 2025',
  Accelerate_v2025 = 'Accelerate 2025',
  Enterprise_v2025 = 'Enterprise 2025',
}

export enum PackageAddonNames {
  Self_Manual_Testing = 'Self manual testing',
  Native_Mobile_Testing = 'Native mobile testing',
  Custom_Severities = 'Custom severities',
  Custom_Rule_Library = 'Custom rule library',
  Scanning_Agent = 'Scanning agent',
  UserWay_Integration = 'UserWay Integration',
  Scan_Rules_Curations = 'Scan Rules Curations',
  Issue_Tracking_Integrations = 'Issue tracking integrations',
  Scanning_And_Reporting_APIs = 'Scanning and reporting APIs',
  Common_Findings = 'Common Findings',
}

export const PLUS_PACKAGE_NAMES: string[] = [
  PackageNames.AcceleratePlus_v2024,
  PackageNames.EssentialsPlus_v2024,
  PackageNames.EnterprisePlus_v2024,
];

export const V2025_PACKAGE_NAMES: PackageNames[] = [
  PackageNames.Basic_v2025,
  PackageNames.Essentials_v2025,
  PackageNames.Accelerate_v2025,
  PackageNames.Enterprise_v2025,
];
