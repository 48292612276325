import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { QuicklinkModule, QuicklinkStrategy } from 'ngx-quicklink';

import { config } from '../environments/config.shared';
import { NotFoundComponent } from './pages/public/not-found/not-found.component';
import { LoginComponent } from './pages/public/login/login.component';
import { LogoutComponent } from './pages/public/logout/logout.component';
import { PasswordResetComponent } from './pages/public/password-reset/password-reset.component';
import { ResendActivationLinkComponent } from './pages/public/activation-link/activation-link.component';
import { CookiePolicyComponent } from './pages/public/cookie-policy/cookie-policy.component';
import { VerifyEmailComponent } from './pages/public/verify-email/verify-email.component';
import { HelpCenterComponent } from './pages/public/help-center/help-center.component';
import { AuthGuard } from './services/auth/guards/auth.guard';
import { Api } from '../../shared/constants/api';
import { ForbiddenErrorComponent } from './pages/public/error/forbidden-error/forbidden-error.component';
import { AttributionComponent } from './pages/public/attribution/attribution.component';
import { DigitalPropertyGuard } from './services/auth/guards/digital-property.guard';
import { ShortLinkComponent } from './pages/public/short-link/short-link.component';
import { NonSsoTenantGuard } from './services/auth/guards/non-sso-tenant.guard';
import {
  RedirectSsoTenantToAuth0LogoutGuard,
  RedirectSsoTenantToSignupGuard,
} from './services/auth/guards/redirect-sso-tenant.guard';
import { FunctionalArea, SecureAction } from '../../shared/constants/security';
import { SecurityGuard } from './services/auth/guards/security.guard';
import { SecurityEntityLevel } from '../../shared/constants/security-group';
import { RequiredSecurities } from '../../shared/constants/required-securities';
import { HomePageRedirectGuard } from './services/auth/guards/home-page-redirect.guard';
import { ProjectAndTasksBreadcrumbsResolver } from './resolvers/breadcrumbs/projects-and-tasks-breadcrumbs.resolver';
import { FeatureFlagCollection } from '../../shared/interfaces/feature-flag.interface';
import { FeatureFlagRedirectionGuard } from './services/feature-flag/feature-flag-redirection.guard';
import { ActivatedRouteData } from './shared/constants';
import { TenantGuard } from './services/auth/guards/tenant.guard';
import { SignupComponent } from './pages/public/signup/signup.component';
import { WebDigitalPropertyGuard } from './services/auth/guards/web-digital-property.guard';
import { TenantLoginRedirectComponent } from './pages/public/login/tenant-login-redirect/tenant-login-redirect.component';
import { TenantlessGuard } from './services/auth/guards/tenantless.guard.service';
import { MobileDigitalPropertyGuard } from './services/auth/guards/mobile-digital-property.guard';
import { FeatureFlagGuard } from './services/feature-flag/feature-flag.guard';

const appRoutes: Routes = [
  {
    path: `${Api.admin}`,
    loadChildren: (): any => import('./admin/admin.module').then((m: any) => m.AdminModule),
    canActivate: [AuthGuard],
    data: {
      title: ['administrator_panel'],
      breadcrumbs: {
        isLinkToPageAvailable: false,
      },
    },
  },
  {
    path: `${Api.signup}`,
    component: SignupComponent,
    canActivate: [RedirectSsoTenantToSignupGuard],
    data: {
      title: ['sign_up_to', ' ' + config.APP.NAME],
    },
  },
  {
    path: `${Api.login}`,
    component: LoginComponent,
    canActivate: [AuthGuard],
    data: {
      title: ['login'],
    },
  },
  {
    path: `${Api.loginOrganizationRedirection}`,
    component: TenantLoginRedirectComponent,
    canActivate: [TenantlessGuard],
    data: {
      title: ['login'],
    },
  },
  {
    path: `${Api.logout}`,
    component: LogoutComponent,
    canActivate: [RedirectSsoTenantToAuth0LogoutGuard],
    data: {
      title: ['logout'],
    },
  },
  {
    path: `${Api.portfolio}`,
    loadChildren: (): any => import('./pages/public/portfolio/portfolio.module').then((m: any) => m.PortfolioModule),
    canActivate: [AuthGuard, TenantGuard, DigitalPropertyGuard],
    data: {
      title: ['heading_menu_portfolio'],
      breadcrumbs: {
        isLinkToPageAvailable: true,
      },
      requiredSecurity: RequiredSecurities.Workspaces_Read,
    },
  },
  {
    path: `${Api.overview}`,
    loadChildren: (): any =>
      import('./pages/public/workspace-dashboard/workspace-dashboard.module').then((m: any) => m.WorkspaceDashboardModule),
    canActivate: [AuthGuard, DigitalPropertyGuard, SecurityGuard],
    data: {
      title: ['dashboard'],
      breadcrumbs: {
        isLinkToPageAvailable: true,
      },
      requiredSecurity: {
        functionalActions: {
          [FunctionalArea.gr_governance]: [SecureAction.read],
        },
      },
    },
  },
  {
    path: `${Api.dashboard}`,
    loadChildren: (): any => import('./pages/public/dashboard/dashboard.module').then((m: any) => m.DashboardModule),
    canActivate: [AuthGuard, DigitalPropertyGuard],
    data: {
      title: ['overview'],
      breadcrumbs: {
        isLinkToPageAvailable: true,
      },
    },
  },
  {
    path: `${Api.projects}`,
    loadChildren: (): any => import('./pages/public/projects/projects.module').then((m: any) => m.ProjectsModule),
    canActivate: [AuthGuard, DigitalPropertyGuard, SecurityGuard],
    data: {
      title: ['projects_label'],
      breadcrumbs: {
        isLinkToPageAvailable: true,
        resolver: ProjectAndTasksBreadcrumbsResolver,
      },
      requiredSecurity: RequiredSecurities.PM_Projects_Read,
    },
  },
  {
    path: `${Api.myTasks}`,
    loadChildren: (): any => import('./pages/public/my-tasks/my-tasks.module').then((m: any) => m.MyTasksModule),
    canActivate: [AuthGuard, DigitalPropertyGuard],
    data: {
      title: ['my_tasks_label'],
      breadcrumbs: {
        resolver: ProjectAndTasksBreadcrumbsResolver,
        isLinkToPageAvailable: true,
      },
    },
  },
  {
    path: `${Api.run_automated_scan}`,
    redirectTo: `${Api.websitesAndApps}/${Api.scans}/${Api.page_scans}/${Api.run_automated_scan}`,
  },
  {
    path: `${Api.run_scan}`,
    redirectTo: `${Api.websitesAndApps}/${Api.scans}/${Api.page_scans}/${Api.run_automated_scan}`,
  },
  {
    path: `${Api.manual_evaluations}`,
    loadChildren: (): any =>
      import('./pages/public/manual-evaluations/manual-evaluations.module').then((m: any) => m.ManualEvaluationsModule),
    canActivate: [AuthGuard, DigitalPropertyGuard, SecurityGuard],
    data: {
      title: ['label_manual_evaluations'],
      breadcrumbs: {
        isLinkToPageAvailable: true,
      },
      requiredSecurity: RequiredSecurities.MT_Manual_Evaluation_Read,
    },
  },
  {
    path: `${Api.designEvaluations}`,
    loadChildren: (): any =>
      import('./admin/design-reviews/design-reviews-view/design-reviews-view.module').then((m: any) => m.DesignReviewsViewModule),
    canActivate: [AuthGuard, DigitalPropertyGuard, SecurityGuard],
    data: {
      title: ['design_evaluations'],
      breadcrumbs: {
        isLinkToPageAvailable: true,
      },
      requiredSecurity: {
        functionalActions: {
          [FunctionalArea.de_results]: [SecureAction.read],
        },
      },
    },
  },
  {
    path: `${Api.designReviews}`,
    loadChildren: (): any => import('./admin/design-reviews/design-reviews.module').then((m: any) => m.DesignReviewsModule),
    canActivate: [AuthGuard, SecurityGuard],
    data: {
      title: ['design_evaluations'],
      breadcrumbs: {
        isLinkToPageAvailable: true,
      },
      requiredSecurity: {
        functionalActions: {
          [FunctionalArea.de_administration]: [SecureAction.read],
        },
      },
    },
  },
  {
    path: `${Api.documents}`,
    loadChildren: (): any => import('./pages/public/documents/documents.module').then((m: any) => m.DocumentsModule),
    canActivate: [AuthGuard, DigitalPropertyGuard, SecurityGuard],
    data: {
      title: ['sidebar_documents'],
      breadcrumbs: {
        isLinkToPageAvailable: true,
      },
      requiredSecurity: {
        functionalActions: {
          [FunctionalArea.documents]: [SecureAction.read],
        },
      },
    },
  },
  {
    path: `${Api.scans}`,
    redirectTo: `${Api.websitesAndApps}/${Api.scans}/${Api.page_scans}`,
  },
  {
    path: `${Api.websitesAndApps}`,
    children: [
      {
        path: Api.scans,
        loadChildren: (): any =>
          import('./pages/public/scans-new-nav/scans-new-nav.module').then((m: any) => m.ScansNewNavModule),
        canActivate: [AuthGuard, WebDigitalPropertyGuard],
        data: {
          title: ['website_and_apps_scans_navigation_item_label'],
          breadcrumbs: {
            isLinkToPageAvailable: true,
            queryParamsHandling: 'none',
          },
        },
      },
      {
        path: Api.mobile_scans,
        loadChildren: (): any => import('./pages/public/mobile-scans/mobile-scans.module').then((m: any) => m.MobileScansModule),
        canActivate: [AuthGuard, MobileDigitalPropertyGuard],
        data: {
          breadcrumbs: {
            isLinkToPageAvailable: true,
            queryParamsHandling: 'none',
          },
        },
      },
      {
        path: Api.mobile_testing_emulator,
        loadChildren: (): any =>
          import('./pages/public/mobile-testing-emulator/mobile-testing-emulator.module').then(
            (m: any) => m.MobileTestingEmulatorModule,
          ),
        canActivate: [AuthGuard, MobileDigitalPropertyGuard, FeatureFlagGuard, SecurityGuard],
        data: {
          title: ['mobile_app_emulator_testing'],
          [ActivatedRouteData.featureFlag]: FeatureFlagCollection.mobileTestingEmulator,
          requiredSecurity: RequiredSecurities.AT_Mobile_Apps_Read,
        },
      },
    ],
  },
  {
    path: `${Api.settings}/${Api.match_components}`,
    redirectTo: `${Api.websitesAndApps}/${Api.scans}/${Api.components}`,
  },
  {
    path: `${Api.settings}`,
    loadChildren: (): any => import('./pages/public/settings/settings.module').then((m: any) => m.SettingsModule),
    data: {
      title: ['sidebar_settings'],
      breadcrumbs: {
        isLinkToPageAvailable: false,
      },
    },
  },
  {
    path: `${Api.integrations}`,
    loadChildren: (): any => import('./pages/public/integrations/integrations.module').then((m: any) => m.IntegrationsModule),
    data: {
      title: ['sidebar_integrations'],
      breadcrumbs: {
        isLinkToPageAvailable: false,
      },
    },
  },
  {
    path: `${Api.monitoring}`,
    loadChildren: (): any => import('./pages/public/monitoring/monitoring.module').then((m: any) => m.MonitoringModule),
    canActivate: [AuthGuard, DigitalPropertyGuard, SecurityGuard, WebDigitalPropertyGuard],
    data: {
      title: ['sidebar_menu_monitoring'],
      breadcrumbs: {
        isLinkToPageAvailable: true,
        queryParamsHandling: 'none',
      },
      requiredSecurity: RequiredSecurities.GR_Monitoring_Read,
    },
  },
  {
    path: `${Api.notifications}`,
    loadChildren: (): any => import('./pages/public/notifications/notifications.module').then((m: any) => m.NotificationsModule),
    canActivate: [AuthGuard, DigitalPropertyGuard, SecurityGuard],
    data: {
      title: ['notifications'],
      requiredSecurity: RequiredSecurities.Notifications_Read,
    },
  },
  {
    path: `${Api.scannableDocuments}`,
    runGuardsAndResolvers: 'pathParamsOrQueryParamsChange',
    loadChildren: (): any =>
      import('./pages/public/scannable-document/scannable-document.module').then((m: any) => m.ScannableDocumentModule),
    canActivate: [AuthGuard, SecurityGuard],
    data: {
      title: ['label_scannable_document'],
      breadcrumbs: {
        isLinkToPageAvailable: true,
      },
      requiredSecurity: {
        functionalActions: {
          [FunctionalArea.pdf_testing]: [SecureAction.read],
        },
        entityLevel: SecurityEntityLevel.digitalProperty,
      },
    },
  },
  {
    path: `${Api.organization}`,
    runGuardsAndResolvers: 'pathParamsOrQueryParamsChange',
    loadChildren: (): any => import('./pages/public/organization/organization.module').then((m: any) => m.OrganizationModule),
    canActivate: [AuthGuard, SecurityGuard],
    data: {
      title: ['organization'],
      breadcrumbs: {
        isLinkToPageAvailable: true,
      },
      requiredSecurity: {
        entityLevel: SecurityEntityLevel.digitalProperty,
        functionalActions: {
          ...RequiredSecurities.GR_Organization_Dashboard_Read.functionalActions,
          ...RequiredSecurities.Organization_Portfolio_Read.functionalActions,
          ...RequiredSecurities.Common_Findings_Read.functionalActions,
        },
      },
    },
  },
  {
    path: `${Api.academy}`,
    runGuardsAndResolvers: 'pathParamsOrQueryParamsChange',
    loadChildren: (): any => import('./pages/public/academy/academy.module').then((m: any) => m.AcademyModule),
    canActivate: [AuthGuard, SecurityGuard],
    data: {
      title: ['academy'],
      breadcrumbs: {
        isLinkToPageAvailable: true,
      },
      requiredSecurity: RequiredSecurities.Academy_Read,
    },
  },
  {
    path: `${Api.attribution}`,
    component: AttributionComponent,
    data: {
      title: ['attribution_label'],
      breadcrumbs: {
        isLinkToPageAvailable: true,
      },
    },
  },
  {
    path: `${Api.cookie_policy}`,
    component: CookiePolicyComponent,
    data: {
      title: ['cookie_policy'],
      breadcrumbs: {
        isLinkToPageAvailable: true,
      },
    },
  },
  {
    path: `${Api.guidelines}`,
    component: HelpCenterComponent,
    data: {
      title: ['sidebar_menu_guidelines'],
      breadcrumbs: {
        isLinkToPageAvailable: true,
      },
    },
  },
  {
    path: `${Api.documentation}`,
    loadChildren: (): any => import('./pages/public/documentation/documentation.module').then((m: any) => m.DocumentationModule),
    canActivate: [AuthGuard],
    data: {
      title: ['sidebar_menu_documentation'],
      breadcrumbs: {
        isLinkToPageAvailable: true,
      },
    },
  },
  {
    path: `${Api.password_reset}`,
    component: PasswordResetComponent,
    data: {
      title: ['password_reset'],
    },
    canActivate: [NonSsoTenantGuard],
  },
  {
    path: `${Api.activation_link}`,
    component: ResendActivationLinkComponent,
    data: {
      title: ['resend_activation_link'],
    },
  },
  {
    path: `${Api.create_new_password}/:${Api.token}`,
    loadChildren: (): any =>
      import('./pages/public/create-new-password/create-new-password.module').then((m: any) => m.CreateNewPasswordModule),
    data: {
      title: ['create_new_password'],
    },
  },
  {
    path: `${Api.verify_email}/:${Api.token}`,
    component: VerifyEmailComponent,
    data: {
      title: ['email_verification'],
    },
  },
  {
    path: `${Api.select_language}`,
    loadChildren: (): any =>
      import('./pages/public/select-language/select-language.module').then((m: any) => m.SelectLanguageModule),
    data: {
      title: ['select_language'],
    },
  },
  {
    path: `${Api.pages}`,
    loadChildren: (): any => import('./pages/public/pages/pages.module').then((m: any) => m.PagesModule),
    canActivate: [AuthGuard, SecurityGuard],
    data: {
      requiredSecurity: RequiredSecurities.Pages_Read,
    },
  },
  {
    path: `${Api.custom_audit_rules}`,
    loadChildren: (): any =>
      import('./pages/public/custom-audit-rules/custom-audit-rules.module').then((m: any) => m.CustomAuditRulesModule),
    canActivate: [AuthGuard, SecurityGuard, FeatureFlagRedirectionGuard],
    data: {
      [ActivatedRouteData.featureFlag]: FeatureFlagCollection.customRulesAndSeverity,
      requiredSecurity: RequiredSecurities.Tenant_Rule_Library_Read,
    },
  },
  {
    path: `${Api.custom_severities}`,
    loadChildren: (): any =>
      import('./pages/public/custom-severities/custom-severities.module').then((m: any) => m.CustomSeveritiesModule),
    canActivate: [AuthGuard, SecurityGuard, FeatureFlagRedirectionGuard],
    data: {
      [ActivatedRouteData.featureFlag]: FeatureFlagCollection.customSeverities,
      requiredSecurity: RequiredSecurities.CustomSeverities_Read,
    },
  },
  {
    path: `${Api.short_link}/:${Api.shortLink}`,
    component: ShortLinkComponent,
    canActivate: [AuthGuard],
    data: {
      title: ['short_link_redirect'],
    },
  },
  {
    path: `${Api.home}`,
    canActivate: [HomePageRedirectGuard],
    children: [],
  },
  {
    path: `${Api.not_found}`,
    component: NotFoundComponent,
    data: {
      title: ['page_not_found_title'],
    },
  },
  {
    path: `${Api.forbidden}`,
    component: ForbiddenErrorComponent,
    data: {
      title: ['page_forbidden_title'],
    },
  },
  { path: '', pathMatch: 'full', redirectTo: `${Api.home}` },
  {
    path: '**',
    redirectTo: '/404',
  },
];

@NgModule({
  imports: [
    QuicklinkModule,
    RouterModule.forRoot(appRoutes, {
      enableTracing: false,
      preloadingStrategy: QuicklinkStrategy,
      paramsInheritanceStrategy: 'always',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
