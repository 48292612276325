import { Injectable } from '@angular/core';

import { WindowRef } from './window.ref';

export enum SESSION_STORAGE {
  __storageTest__ = '__storageTest__',
  GUIDED_USER_FLOW = 'GUIDED_USER_FLOW',
  AUTOMATED_USER_FLOW = 'AUTOMATED_USER_FLOW',
  MOBILE_EMULATOR_SCAN = 'MOBILE_EMULATOR_SCAN',
}

@Injectable()
export class BrowserSessionStorageService {
  private storage: Storage;
  private isStorageAvailable: boolean = false;
  private tempData: unknown = {};

  constructor(private windowRef: WindowRef) {
    this.isStorageAvailable = this.isAvailable();
  }

  private isAvailable(): boolean {
    const testItem: SESSION_STORAGE = SESSION_STORAGE.__storageTest__;
    this.storage = this.windowRef.windowObject.sessionStorage;

    try {
      this.storage.setItem(testItem, testItem);
      this.storage.removeItem(testItem);
      return true;
    } catch (e) {
      return false;
    }
  }

  public getItem<T>(key: SESSION_STORAGE): T | null {
    if (this.isStorageAvailable) {
      return JSON.parse(this.storage.getItem(key));
    }

    let value: string | null = null;

    if (typeof this.tempData[key] === 'string') {
      value = this.tempData[key];
    }

    return JSON.parse(value);
  }

  public setItem(key: SESSION_STORAGE, value: any): void {
    if (this.isStorageAvailable) {
      this.storage.setItem(key, JSON.stringify(value));
      return;
    }

    this.tempData[key] = JSON.stringify(value);
  }

  public removeItem(key: string): void {
    if (this.isStorageAvailable) {
      this.storage.removeItem(key);
      return;
    }

    if (typeof this.tempData[key] === 'string') {
      delete this.tempData[key];
    }
  }

  public clear(): void {
    if (this.isStorageAvailable) {
      this.storage.clear();
      return;
    }

    this.tempData = {};
  }

  public get length(): number {
    if (this.isStorageAvailable) {
      return this.storage.length;
    }

    return Object.keys(this.tempData).length;
  }
}
