import { AuditToolCommonNames } from './audit-tool';

export enum $digitalProperty {
  _id = '_id',
  authorizedDomains = 'authorizedDomains',
  createdAt = 'createdAt',
  entriesPerPage = 'entriesPerPage',
  monitoringData = 'monitoringData',
  name = 'name',
  noDomainRestrictions = 'noDomainRestrictions',
  propertyKey = 'propertyKey',
  type = 'type',
  typeId = 'typeId',
  updatedAt = 'updatedAt',
  url = 'url',
  workspace = 'workspace',
  workspaceId = 'workspaceId',
  scanResults = 'scanResults',
  monitoringResults = 'monitoringResults',
  monitoring = 'monitoring',
  manualIssuesBySeverity = 'manualIssuesBySeverity',
  status = 'status',
  config = 'config',
  pagesLimit = 'pagesLimit',
  defaultTool = 'defaultTool',
  accessibilityStatement = 'accessibilityStatement',
  conformanceLevel = 'conformanceLevel',
  componentMatcherOnlyDefaultTool = 'componentMatcherOnlyDefaultTool',
  userWaySiteHostName = 'userWaySiteHostName',
  useScanningAgent = 'useScanningAgent',
}

export enum $listScanResults {
  latestScanResult = 'latestScanResult',
  previousScanResult = 'previousScanResult',
}

export enum $listScanResult {
  numOfCriticalIssue = 'numOfCriticalIssue',
  processingEndedAt = 'processingEndedAt',
  scanId = 'scanId',
  score = 'score',
  testingTool = 'testingTool',
}

export enum $digitalPropertyScanResults {
  latestScanResult = 'latestScanResult',
  previousScanResult = 'previousScanResult',
}

export enum $digitalPropertyScanResult {
  scan = 'scan',
  scanSummary = 'scanSummary',
  numOfCriticalIssue = 'numOfCriticalIssue',
}

export enum DigitalPropertyAction {
  Create = 'Create',
  Update = 'Update',
}

export enum $digitalPropertyType {
  _id = '_id',
  name = 'name',
  canRestrictDomain = 'canRestrictDomain',
}

export enum DigitalPropertyTypeNames {
  website = 'Website',
  wireframes = 'Wireframes',
  android = 'Android app',
  ios = 'iOS app',
  website_with_mobile = 'Website (mobile responsive)',
  other = 'Other',
  website_no_mobile = 'Website (non-mobile)',
  hardware = 'Hardware',
}

export enum DigitalPropertyStatus {
  active = 'active',
  archived = 'archived',
}

export const $digitalPropertyKeyRegExp: RegExp = /^[a-zA-Z0-9]{2,6}$/;

export enum $digitalPropertyMonitoring {
  enabled = 'enabled',
  creatorUserId = 'creatorUserId',
  conformanceLevel = 'conformanceLevel',
  headers = 'headers',
  monitoring = 'monitoring',
  occurrence = 'occurrence',
  viewport = 'viewport',
  scanAuthenticationType = 'scanAuthenticationType',
  scanAuthenticationPassword = 'scanAuthenticationPassword',
  scanAuthenticationUserName = 'scanAuthenticationUserName',
  secureWebAuthentication = 'secureWebAuthentication',
  pagesToMonitor = 'pagesToMonitor',
  sitemapUrl = 'sitemapUrl',
  alerts = 'alerts',
  nextScheduledAt = 'nextScheduledAt',
  digitalPropertyId = 'digitalPropertyId',
  crawler = 'crawler',
  isQuickSetup = 'isQuickSetup',
  startAt = 'startAt',
  discoveryMethod = 'discoveryMethod',
  runFirstScanImmediately = 'runFirstScanImmediately',
  handlePopupsSelector = 'handlePopupsSelector',
  preScanFlowId = 'preScanFlowId',
  waitTimeSec = 'waitTimeSec',
  includeIframes = 'includeIframes',
}

export enum $digitalPropertyMonitoringAlerts {
  alertType = 'alertType',
  threshold = 'threshold',
  peopleToNotify = 'peopleToNotify',
  alerts = 'alerts',
  email = 'email',
  userId = 'userId',
}

export enum $digitalPropertyMonitoringAlertType {
  scoreDropByPercentFromPrevious = 'scoreDropByPercentFromPrevious',
  scoreDropByPercent = 'scoreDropByPercent',
  scanCompletion = 'scanCompletion',
}

export enum MonitoringStatus {
  yes = 'yes',
  no = 'no',
}

export const $digitalPropertyMonitoringAlertsThresholdMin: number = 1;
export const $digitalPropertyMonitoringAlertsThresholdMax: number = 100;

export const $digitalPropertyMonitoringPagesLimitDefault: number = 100;

// TODO: Fallback - Remove this when there are no more tenantless workspaces.
export const $digitalPropertyPagesLimitMax: number = 300;

export const $maxAlertsRecipientCount: number = 10;
export const $digitalPropertyDefaultTool: AuditToolCommonNames = AuditToolCommonNames.access_engine;

export interface ISelectAuditTool {
  displayName: string;
  value: AuditToolCommonNames;
}

export const CHAR_LIMITS: Readonly<Record<string, number>> = {
  [$digitalProperty.name]: 100,
};

export enum MonitoringDiscoveryMethod {
  crawl = 'crawl',
  sitemap = 'sitemap',
  listOfPages = 'listOfPages',
}

export const MOBILE_DIGITAL_PROPERTY_TYPE_NAMES: Readonly<string[]> = Object.freeze([
  DigitalPropertyTypeNames.android,
  DigitalPropertyTypeNames.ios,
]);
