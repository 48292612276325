import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NgbPaginationConfig } from '@ng-bootstrap/ng-bootstrap';
import { partition } from 'lodash';
import { Observable, Subscription, combineLatest, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import { IPageSummary, IScanSummary, IUserFlowScanPointSummary } from '../../../../shared/interfaces/scan-summaries.interface';
import {
  IDclConfig,
  ITableColumn,
  ITableConfig,
  ITableEmptyState,
  ITableRow,
  SortEvent,
} from '../table/ngb-table/utilities/ngb-table.interface';
import { TranslateService } from '../../translate/translate.service';
import { severityToComparable } from '../../../../shared/constants/accessibility';
import { $sortingOrder } from '../../../../shared/constants/sort';
import { NgbTableUtilities } from '../table/ngb-table/utilities/ngb-table.utilities';
import { AuditStandards } from '../../../../shared/constants/audit-standard';
import { NgbTableComponent } from '../table/ngb-table/ngb-table.component';
import { ISuccessCriteria } from '../../../../shared/audits/definitions/success-criteria/success-criteria.interface';
import { AccessibilityAuditToolNames } from '../../../../shared/constants/audit-tool';
import { SharedAuditsUtility } from '../../../../shared/utils/audits.utility';
import { IAuditIssueViewData, IAuditIssueViewQueryParams } from '../../../../shared/interfaces/audit-issues-view.interface';
import { $auditIssuesView, auditIssuesViewStatus } from '../../../../shared/constants/audit-issues-view';
import { SharedSortUtility } from '../../../../shared/utils/sort.utility';
import { ApiQueryOption } from '../../../../shared/constants/api';
import { SupplementaryTableController } from './supplementary-table-controller';
import { AclSecurityAdapter } from '../../services/acl.service';
import { UserAclService } from '../../services/user-acl.service';
import { RequiredSecurities } from '../../../../shared/constants/required-securities';
import { SharedCommonUtility } from '../../../../shared/utils/common.utility';
import { SuccessCriteriaService } from '../../services/success-criteria.service';
import { CriteriaPreset, Separator, SuccessCriteriaFormatterService } from '../../services/success-criteria-formatter.service';
import { FeatureFlagService } from '../../services/feature-flag/feature-flag.service';
import { TenantSeveritiesService } from '../../services/tenant-severities.service';
import { ICustomSeveritiesMap } from '../../../../shared/interfaces/tenant.interface';
import { FeatureFlagCollection } from '../../../../shared/interfaces/feature-flag.interface';
import { ISeverityCellConfig } from '../table/ngb-table/cells/severity-cell/severity-cell.component';
import { IDSSeverityCellConfig } from '../table/ngb-table/cells/ds-severity-cell/ds-severity-cell.component';
import { ScanOrigin } from '../../../../shared/constants/scanning';

enum tableHeaders {
  ruleDescription = 'ruleDescription',
  successCriteriaConformanceLevel = 'successCriteriaConformanceLevel',
  severity = 'severity',
  elements = 'elements',
  open = 'open',
  dismissed = 'dismissed',
  scoreImpact = 'scoreImpact',
  findings = 'findings',
}

interface IFailedHeaderOption {
  addScoreData: boolean;
}

interface IIssueStatus {
  filter: (datum: IAuditIssueViewData) => boolean;
  header: tableHeaders[];
  value: (datum: IAuditIssueViewData) => number[]; // the number of issues
  applicableHeaders: (options?: IFailedHeaderOption) => string[];
  isDescriptionLink: boolean;
}

type ISummary = IPageSummary | IScanSummary | IUserFlowScanPointSummary;

const FailedHandler: IIssueStatus = {
  filter: SharedAuditsUtility.hasAuditIssuesViewStatus.bind(SharedAuditsUtility, auditIssuesViewStatus.failed),
  header: [tableHeaders.open, tableHeaders.dismissed, tableHeaders.scoreImpact],
  value: (datum: IAuditIssueViewData): number[] => [
    datum[$auditIssuesView.failures],
    datum[$auditIssuesView.ignores],
    datum[$auditIssuesView.scoreImpact],
  ],
  applicableHeaders: (options: IFailedHeaderOption): string[] => {
    const noImpactHeaders: string[] = [
      tableHeaders.ruleDescription,
      tableHeaders.successCriteriaConformanceLevel,
      tableHeaders.severity,
      tableHeaders.open,
      tableHeaders.dismissed,
    ];
    if (options.addScoreData) {
      return [...noImpactHeaders, tableHeaders.scoreImpact];
    }
    return noImpactHeaders;
  },
  isDescriptionLink: true,
};

const ReviewHandler: IIssueStatus = {
  filter: SharedAuditsUtility.hasAuditIssuesViewStatus.bind(SharedAuditsUtility, auditIssuesViewStatus.review),
  header: [tableHeaders.findings],
  value: (datum: IAuditIssueViewData): number[] => [datum[$auditIssuesView.failures]],
  applicableHeaders: () => [tableHeaders.ruleDescription, tableHeaders.successCriteriaConformanceLevel, tableHeaders.findings],
  isDescriptionLink: true,
};

const PassedHandler: IIssueStatus = {
  filter: SharedAuditsUtility.hasAuditIssuesViewStatus.bind(SharedAuditsUtility, auditIssuesViewStatus.passed),
  header: [tableHeaders.elements],
  value: (datum: IAuditIssueViewData): number[] => [datum[$auditIssuesView.passes]],
  applicableHeaders: () => [tableHeaders.ruleDescription, tableHeaders.successCriteriaConformanceLevel, tableHeaders.elements],
  isDescriptionLink: false,
};

const issueStatusToHandler: Record<auditIssuesViewStatus, IIssueStatus> = {
  [auditIssuesViewStatus.failed]: FailedHandler,
  [auditIssuesViewStatus.review]: ReviewHandler,
  [auditIssuesViewStatus.passed]: PassedHandler,
};

@Component({
  selector: 'app-rule-issues-table',
  templateUrl: './rule-issues-table.component.html',
  styleUrls: ['./rule-issues-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [NgbPaginationConfig],
})
export class RuleIssuesTableComponent implements OnInit, OnChanges, OnDestroy {
  private readonly rawTableData: Record<auditIssuesViewStatus, IAuditIssueViewData[]>;

  public manualReviewTable: SupplementaryTableController;
  public recommendationsTable: SupplementaryTableController;

  @ViewChild('issuesTable') private issueTable: NgbTableComponent;

  @Input() public summary: ISummary;
  @Input() public auditTool: AccessibilityAuditToolNames;
  @Input() public scanId: string;
  @Input() public addScoreData: boolean;
  @Input() public isMonitoringTag: boolean;
  @Input() public isMonitoring: boolean;
  @Input() public flawPageId?: string;
  @Input() public componentId?: string;
  @Input() public scanOrigin?: ScanOrigin;

  public tableConfig: Record<auditIssuesViewStatus, ITableConfig>;
  public tableData: Record<auditIssuesViewStatus, ITableRow[]>;
  public filteredPaginatedTableData: ITableRow[];

  public entriesPerPage: number;
  public page: number;
  public entriesAmounts: number[];
  public issueStatusFilter: auditIssuesViewStatus;
  public AccessibilityAuditToolNames: typeof AccessibilityAuditToolNames;

  private subscription: Subscription = new Subscription();
  private canRunSupplementalScan: boolean;
  private customSeverities: ICustomSeveritiesMap;

  constructor(
    private translateService: TranslateService,
    private paginationConfig: NgbPaginationConfig,
    private userAclService: UserAclService,
    private successCriteriaService: SuccessCriteriaService,
    private successCriteriaFormatterService: SuccessCriteriaFormatterService,
    private featureFlagService: FeatureFlagService,
    private tenantSeveritiesService: TenantSeveritiesService,
    private changeDetector: ChangeDetectorRef,
  ) {
    this.addScoreData = false;
    this.paginationConfig.maxSize = 2;

    this.rawTableData = {
      [auditIssuesViewStatus.failed]: [],
      [auditIssuesViewStatus.review]: [],
      [auditIssuesViewStatus.passed]: [],
    };
    this.tableData = {
      [auditIssuesViewStatus.failed]: [],
      [auditIssuesViewStatus.review]: [],
      [auditIssuesViewStatus.passed]: [],
    };
    this.entriesAmounts = [5, 10, 20];
    this.entriesPerPage = this.entriesAmounts[1];
    this.page = 1;
    this.filterStatus(auditIssuesViewStatus.failed);

    this.tableConfig = null;

    this.AccessibilityAuditToolNames = AccessibilityAuditToolNames;
    this.isMonitoringTag = false;
    this.manualReviewTable = new SupplementaryTableController(
      translateService,
      this.entriesAmounts,
      this.successCriteriaFormatterService,
    );
    this.recommendationsTable = new SupplementaryTableController(
      translateService,
      this.entriesAmounts,
      this.successCriteriaFormatterService,
    );
  }

  private getAllTableConfig(): Record<auditIssuesViewStatus, ITableConfig> {
    return {
      [auditIssuesViewStatus.failed]: this.getTableConfig(auditIssuesViewStatus.failed, {
        iconId: 'highfive',
        title: this.translateService.instant('there_are_no_failed_findings'),
        subtitle: this.translateService.instant('scan_results_aggregated_issues_failed_empty_state_subtitle'),
        iconHeight: '80',
        iconWidth: '100',
        iconFill: 'none',
      }),
      [auditIssuesViewStatus.review]: this.getTableConfig(auditIssuesViewStatus.review, {
        iconId: 'clipboard-icon',
        title: this.translateService.instant('scan_results_aggregated_issues_review_empty_state_title'),
        subtitle: this.translateService.instant('scan_results_aggregated_issues_review_empty_state_subtitle'),
        iconHeight: '90',
        iconWidth: '70',
        iconFill: 'none',
      }),
      [auditIssuesViewStatus.passed]: this.getTableConfig(auditIssuesViewStatus.passed, {
        iconId: 'clipboard-red',
        title: this.translateService.instant('scan_results_aggregated_issues_passed_empty_state_title'),
        subtitle: this.translateService.instant('passed_no_results_available_description'),
        iconHeight: '90',
        iconWidth: '70',
        iconFill: 'none',
      }),
    };
  }

  private getTableConfig(issueStatusFilter: auditIssuesViewStatus, emptyState: ITableEmptyState): ITableConfig {
    const allColumns: { [key: string]: ITableColumn } = {
      [tableHeaders.ruleDescription]: {
        translationKey: 'table_header_rule_description',
        sortingEnabled: true,
        styles: {
          maxWidth: '45%',
          width: '45%',
        },
      },
      [tableHeaders.successCriteriaConformanceLevel]: {
        translationKey: 'table_header_sc_conformance_level',
        sortingEnabled: true,
        styles: {
          whiteSpace: 'pre-line',
        },
      },
      [tableHeaders.severity]: {
        translationKey: 'table_column_severity',
        sortingEnabled: true,
      },
    };
    for (const header of issueStatusToHandler[issueStatusFilter].header) {
      allColumns[header] = {
        translationKey: header,
        sortingEnabled: true,
      };
    }

    const tableConfig: ITableConfig = {
      emptyState: emptyState,
      caption: this.translateService.instant('findings_discovered'),
      columns: {},
    };

    const applicableHeaders: string[] = issueStatusToHandler[issueStatusFilter].applicableHeaders({
      addScoreData: this.addScoreData,
    });
    for (const applicableHeader of applicableHeaders) {
      tableConfig.columns[applicableHeader] = allColumns[applicableHeader];
    }
    return tableConfig;
  }

  private getComparable(column: string, rawDatum: IAuditIssueViewData): string {
    for (const [index, header] of issueStatusToHandler[this.issueStatusFilter].header.entries()) {
      if (column === header) {
        return String(issueStatusToHandler[this.issueStatusFilter].value(rawDatum)[index]);
      }
    }
    switch (column) {
      case tableHeaders.successCriteriaConformanceLevel:
        return this.displaySuccessCriteriasFull(rawDatum[$auditIssuesView.successCriteriaIdentifier]);
      case tableHeaders.severity:
        return severityToComparable[rawDatum[$auditIssuesView.severity]];
      case tableHeaders.findings:
        return String(rawDatum[$auditIssuesView.failures]);
      default:
        return String(rawDatum[column]);
    }
  }

  private displaySuccessCriteriasFull(identifiers: string[]): string {
    return this.successCriteriaFormatterService.toDisplayCriterias({
      identifiers: identifiers ?? [],
      separator: Separator.newLine,
      preset: CriteriaPreset.criteriaFull,
    });
  }

  private extractData(summary: ISummary): [IAuditIssueViewData[], IAuditIssueViewData[], IAuditIssueViewData[]] {
    const [wcagIssues, recommendations]: IAuditIssueViewData[][] = SharedAuditsUtility.getAuditIssuesViewData(
      summary,
      this.auditTool,
      this.scanId,
      this.addScoreData,
      this.flawPageId,
      this.componentId,
      this.isMonitoring,
      this.scanOrigin,
    );

    const [manualReviewIssues, issues]: IAuditIssueViewData[][] = partition(wcagIssues, SharedAuditsUtility.isManualReviewStatus);

    return [issues, recommendations, manualReviewIssues];
  }

  private isDescriptionLink(issueStatus: IIssueStatus): boolean {
    return issueStatus.isDescriptionLink && !this.isMonitoringTag;
  }

  private toTableRows(
    issueStatusFilter: auditIssuesViewStatus,
    rawData: IAuditIssueViewData[],
    canRunSupplementalScan: boolean,
  ): ITableRow[] {
    const toTableRow = (entry: IAuditIssueViewData): ITableRow => {
      const queryParams: IAuditIssueViewQueryParams = entry[$auditIssuesView.queryParams];
      queryParams[ApiQueryOption.severity] = entry[$auditIssuesView.severity];
      queryParams[ApiQueryOption.ruleDescription] = entry[$auditIssuesView.ruleDescription];
      queryParams[ApiQueryOption.identifier] = entry[$auditIssuesView.successCriteriaIdentifier];
      queryParams[ApiQueryOption.totalFinding] =
        entry[$auditIssuesView.failures] + entry[$auditIssuesView.ignores] + entry[$auditIssuesView.passes];

      const severityCell: IDclConfig<ISeverityCellConfig | IDSSeverityCellConfig> = SharedCommonUtility.notNullish(
        this.customSeverities?.get(entry[$auditIssuesView.severity]),
      )
        ? NgbTableUtilities.DSSeverityCell({
            severity: entry[$auditIssuesView.severity],
            customSeverity: this.customSeverities.get(entry[$auditIssuesView.severity]),
          })
        : NgbTableUtilities.severityCell({
            severity: entry[$auditIssuesView.severity],
          });

      const tableRow: ITableRow = {
        data: {
          [tableHeaders.ruleDescription]:
            canRunSupplementalScan && this.isDescriptionLink(issueStatusToHandler[issueStatusFilter])
              ? NgbTableUtilities.linkCell({
                  text: entry[$auditIssuesView.ruleDescription],
                  attributes: {
                    routerLink: entry[$auditIssuesView.link],
                    queryParams,
                    queryParamsHandling: 'merge',
                  },
                })
              : NgbTableUtilities.textCell({
                  text: entry[$auditIssuesView.ruleDescription],
                }),
          [tableHeaders.successCriteriaConformanceLevel]: NgbTableUtilities.textCell({
            text: this.displaySuccessCriteriasFull(entry[$auditIssuesView.successCriteriaIdentifier]),
          }),
          [tableHeaders.severity]: severityCell,
        },
      };
      for (const [index, header] of issueStatusToHandler[issueStatusFilter].header.entries()) {
        tableRow.data[header] = NgbTableUtilities.textCell({
          text: String(issueStatusToHandler[issueStatusFilter].value(entry)[index]),
        });
      }
      return tableRow;
    };

    return rawData.filter(issueStatusToHandler[issueStatusFilter].filter).map(toTableRow);
  }

  private initialSort(issueStatusFilter: auditIssuesViewStatus): void {
    const sortFunction = (a: IAuditIssueViewData, b: IAuditIssueViewData): number => {
      const aWcagStandard: ISuccessCriteria = this.successCriteriaService.getSuccessCriteriaFromStandard(
        AuditStandards.wcag,
        a[$auditIssuesView.successCriteriaIdentifier][0],
      );
      const bWcagStandard: ISuccessCriteria = this.successCriteriaService.getSuccessCriteriaFromStandard(
        AuditStandards.wcag,
        b[$auditIssuesView.successCriteriaIdentifier][0],
      );

      const aSeverity: string = this.getComparable(tableHeaders.severity, a);
      const bSeverity: string = this.getComparable(tableHeaders.severity, b);

      const aIssues: string = this.getComparable(issueStatusToHandler[issueStatusFilter].header[0], a);
      const bIssues: string = this.getComparable(issueStatusToHandler[issueStatusFilter].header[0], b);

      return (
        bSeverity.localeCompare(aSeverity, 'en-US', { numeric: true, sensitivity: 'base' }) ||
        aIssues.localeCompare(bIssues, 'en-US', { numeric: true, sensitivity: 'base' }) ||
        aWcagStandard.versions[0].localeCompare(bWcagStandard.versions[0], 'en-US', {
          numeric: true,
          sensitivity: 'base',
        }) ||
        aWcagStandard.level.localeCompare(bWcagStandard.level)
      );
    };

    this.rawTableData[issueStatusFilter].sort(sortFunction);
  }

  private reload(): void {
    const [issues, recommendations, manualReviewRequired]: IAuditIssueViewData[][] = this.extractData(this.summary);

    this.manualReviewTable.setIssueData(
      manualReviewRequired,
      {
        ruleDescriptionAsLink: !this.isMonitoringTag,
        displayAdditionalColumns: true,
      },
      this.customSeverities,
    );
    this.recommendationsTable.setIssueData(
      recommendations,
      {
        ruleDescriptionAsLink: !this.isMonitoringTag,
        displayAdditionalColumns: false,
      },
      this.customSeverities,
    );

    for (const issueStatusFilter of Object.values(auditIssuesViewStatus)) {
      this.rawTableData[issueStatusFilter] = issues.filter(issueStatusToHandler[issueStatusFilter].filter);
      this.initialSort(issueStatusFilter);
      this.tableData[issueStatusFilter] = this.toTableRows(
        issueStatusFilter,
        this.rawTableData[issueStatusFilter],
        this.canRunSupplementalScan,
      );
    }

    this.setFilteredPaginatedTable();
  }

  private getPage(data: ITableRow[], page: number, entriesPerPage: number): ITableRow[] {
    const start: number = (page - 1) * entriesPerPage;
    const end: number = page * entriesPerPage;

    return data.slice(start, end);
  }

  private setFilteredPaginatedTable(): void {
    this.filteredPaginatedTableData = this.getPage(this.filteredTableData, this.page, this.entriesPerPage);
  }

  private tableDefaultSort(rows: IAuditIssueViewData[]): IAuditIssueViewData[] {
    const sortByDescription = (a: IAuditIssueViewData, b: IAuditIssueViewData): number => {
      const aValue: string = this.getComparable($auditIssuesView.ruleDescription, a);
      const bValue: string = this.getComparable($auditIssuesView.ruleDescription, b);

      return aValue.localeCompare(bValue, 'en-US', { numeric: true, sensitivity: 'base' });
    };
    const sortByIssues = (a: IAuditIssueViewData, b: IAuditIssueViewData): number => {
      const aValue: number = Number(this.getComparable($auditIssuesView.failures, a));
      const bValue: number = Number(this.getComparable($auditIssuesView.failures, b));

      return bValue - aValue;
    };

    return rows
      .sort(sortByDescription)
      .sort(sortByIssues)
      .sort(SharedSortUtility.functionSortObjectBySeverity($sortingOrder.desc, $auditIssuesView.severity));
  }

  public get hasComponentId(): boolean {
    return SharedCommonUtility.notNullishOrEmpty(this.componentId);
  }

  public get filteredTableData(): ITableRow[] {
    return this.tableData[this.issueStatusFilter];
  }

  public get filteredTableConfig(): ITableConfig {
    return this.tableConfig[this.issueStatusFilter];
  }

  public get failedRulesCount(): number {
    return this.tableData[auditIssuesViewStatus.failed].length + this.manualReviewTable.dataLength;
  }

  public get reviewRulesCount(): number {
    return this.tableData[auditIssuesViewStatus.review].length;
  }

  public get passedRulesCount(): number {
    return this.tableData[auditIssuesViewStatus.passed].length;
  }

  public get showRuleIssuesSupplementartyTable(): boolean {
    return this.manualReviewTable.dataLength > 0 && this.issueStatusFilter === auditIssuesViewStatus.failed;
  }

  public filterStatus(event: auditIssuesViewStatus): void {
    this.issueStatusFilter = event;
    if (typeof this.issueTable !== 'undefined') {
      this.issueTable.reloadWithNewConfig(this.filteredTableConfig);
    }
    this.setFilteredPaginatedTable();
  }

  private sortFunc(direction: $sortingOrder, aValue: string | number, bValue: string | number): number {
    let ascending: number = 0;
    if (direction === $sortingOrder.all) {
      return ascending;
    }
    if (typeof aValue === 'number' && typeof bValue === 'number') {
      ascending = aValue - bValue;
    } else {
      ascending = String(aValue).localeCompare(String(bValue), 'en-US', { numeric: true, sensitivity: 'base' });
    }

    return direction === $sortingOrder.asc ? ascending : -ascending;
  }

  public onTableSort({ column, direction }: SortEvent): void {
    const sortFunc = (a: IAuditIssueViewData, b: IAuditIssueViewData): number => {
      const aValue: string = this.getComparable(column, a);
      const bValue: string = this.getComparable(column, b);

      return this.sortFunc(direction, aValue, bValue);
    };

    if (direction === $sortingOrder.all) {
      this.tableData[this.issueStatusFilter] = this.toTableRows(
        this.issueStatusFilter,
        this.tableDefaultSort(this.rawTableData[this.issueStatusFilter]),
        this.canRunSupplementalScan,
      );
    } else {
      this.tableData[this.issueStatusFilter] = this.toTableRows(
        this.issueStatusFilter,
        [...this.rawTableData[this.issueStatusFilter]].sort(sortFunc),
        this.canRunSupplementalScan,
      );
    }

    this.setFilteredPaginatedTable();
  }

  public onPageChange(page: number): void {
    this.page = page;
    this.setFilteredPaginatedTable();
  }

  public onEntriesPerPageChange(entriesPerPage: number): void {
    this.entriesPerPage = entriesPerPage;
    this.setFilteredPaginatedTable();
  }

  public getPaginationLabel(page: number, entriesPerPage: number, total: number): string {
    return this.translateService.instant('n_of_t', [
      `${Math.min((page - 1) * entriesPerPage + 1, total)} - ${Math.min(page * entriesPerPage, total)}`,
      String(total),
    ]);
  }

  public ngOnInit(): void {
    const canRunSupplementalScan$: Observable<boolean> = this.userAclService.createCheckAccessForCurrentUser().pipe(
      map((adapter: AclSecurityAdapter): boolean =>
        adapter
          .useWorkspaceFromUser()
          .useDigitalPropertyFromUser()
          .useFunctionalActions(RequiredSecurities.AT_Scans_Read.functionalActions)
          .check(),
      ),
      tap((canRunSupplementalScan: boolean): void => {
        this.canRunSupplementalScan = canRunSupplementalScan;
      }),
    );

    const customSeverities$: Observable<ICustomSeveritiesMap> = this.featureFlagService
      .variation$(FeatureFlagCollection.customSeverities, false)
      .pipe(
        switchMap((customSeveritiesEnabled: boolean): Observable<ICustomSeveritiesMap> => {
          if (!customSeveritiesEnabled) {
            return of(null);
          }

          return this.tenantSeveritiesService.getAll();
        }),
      );

    this.tableConfig = this.getAllTableConfig();

    this.subscription.add(
      combineLatest([canRunSupplementalScan$, customSeverities$])
        .pipe(
          tap(([canRunSupplementalScan, customSeverities]: [boolean, ICustomSeveritiesMap]): void => {
            this.canRunSupplementalScan = canRunSupplementalScan;
            this.customSeverities = customSeverities;
            this.reload();
            this.changeDetector.detectChanges();
          }),
        )
        .subscribe(),
    );
  }

  public ngOnChanges(): void {
    this.tableConfig = this.getAllTableConfig();
    this.reload();
  }

  public ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
